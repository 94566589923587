import React, { createContext, useContext, useEffect, useRef, useState } from "react";

// Create a context for audio
const AudioContext = createContext();

export const AudioProvider = ({ children }) => {
  const [audioCtx, setAudioCtx] = useState(null);
  const clickSoundBuffer = useRef(null);
  const clickSoundBuffer2 = useRef(null);
  const loopSource = useRef(null);
  const newSoundBuffer1 = useRef(null);
  const newSoundBuffer2 = useRef(null);
  const newSoundBuffer3 = useRef(null);
  const currentAudioSource = useRef(null);

  useEffect(() => {
    // Initialize AudioContext
    const context = new (window.AudioContext || window.webkitAudioContext)();
    setAudioCtx(context);

    // Load the click sound
    const loadClickSound = async () => {
      const response = await fetch("/assets/Audios/clicksoundeffect.mp3");
      const arrayBuffer = await response.arrayBuffer();
      clickSoundBuffer.current = await context.decodeAudioData(arrayBuffer);

      // const response2 = await fetch(
      //   "https://assets.mixkit.co/active_storage/sfx/1117/1117-preview.mp3"
      // );

      const response2 = await fetch("https://assets.mixkit.co/active_storage/sfx/1119/1119-preview.mp3");
      const arrayBuffer2 = await response2.arrayBuffer();
      clickSoundBuffer2.current = await context.decodeAudioData(arrayBuffer2);

      // Load the additional audio files
      const response3 = await fetch("/assets/Audios/game-cracker-audios/cracker-one.mp3");
      const arrayBuffer3 = await response3.arrayBuffer();
      newSoundBuffer1.current = await context.decodeAudioData(arrayBuffer3);

      const response4 = await fetch("/assets/Audios/game-cracker-audios/cracker-two.mp3");
      const arrayBuffer4 = await response4.arrayBuffer();
      newSoundBuffer2.current = await context.decodeAudioData(arrayBuffer4);

      const response5 = await fetch("/assets/Audios/game-cracker-audios/cracker-three.mp3");
      const arrayBuffer5 = await response5.arrayBuffer();
      newSoundBuffer3.current = await context.decodeAudioData(arrayBuffer5);
    };

    loadClickSound();

    return () => {
      // Clean up AudioContext on component unmount
      if (audioCtx) {
        audioCtx.close();
      }
    };
  }, []);

  const playClickSound = () => {
    if (audioCtx && clickSoundBuffer.current) {
      const source = audioCtx.createBufferSource();
      source.buffer = clickSoundBuffer.current;
      source.connect(audioCtx.destination);
      source.start();
    }
  };

  const startLoopingClickSound = (initialPlaybackRate = 2.5, constantDuration = 3500, slowdownDuration = 5000) => {
    if (audioCtx && clickSoundBuffer2.current) {
      // Stop any existing loop
      if (loopSource.current) {
        loopSource.current.stop();
      }

      const source = audioCtx.createBufferSource();
      source.buffer = clickSoundBuffer2.current;
      source.loop = true; // Set loop to true

      // Create playbackRate automation
      const playbackRate = source.playbackRate;
      const currentTime = audioCtx.currentTime;

      playbackRate.setValueAtTime(initialPlaybackRate, currentTime);

      setTimeout(() => {
        console.log("slows");
        // Set initial playback rate and keep it constant for `constantDuration`
        playbackRate.setValueAtTime(initialPlaybackRate, currentTime);

        // Schedule the playback rate to start decreasing after `constantDuration`
        const slowdownStartTime = currentTime + constantDuration / 1000;
        const targetPlaybackRate = 0.5;

        playbackRate.linearRampToValueAtTime(targetPlaybackRate, slowdownStartTime + slowdownDuration / 1000);
      }, 2800);

      source.connect(audioCtx.destination);
      source.start();
      loopSource.current = source;
    }
  };

  const stopLoopingClickSound = () => {
    if (loopSource.current) {
      loopSource.current.stop();
      loopSource.current = null;
    }
  };

  const adjustLoopPlaybackRate = (rate) => {
    if (loopSource.current) {
      loopSource.current.playbackRate.setValueAtTime(rate, audioCtx.currentTime);
    }
  };

  const generateSound = (type = "sine", frequency = 440, duration = 0.2) => {
    if (audioCtx) {
      const oscillator = audioCtx.createOscillator();
      const gainNode = audioCtx.createGain();

      oscillator.type = type; // Waveform type: 'sine', 'square', 'sawtooth', 'triangle'
      oscillator.frequency.setValueAtTime(frequency, audioCtx.currentTime); // Frequency in Hz

      oscillator.connect(gainNode);
      gainNode.connect(audioCtx.destination);

      // Set gain (volume) and play for the specified duration
      gainNode.gain.setValueAtTime(1, audioCtx.currentTime);
      gainNode.gain.exponentialRampToValueAtTime(0.001, audioCtx.currentTime + duration);

      oscillator.start(audioCtx.currentTime);
      oscillator.stop(audioCtx.currentTime + duration); // Stop sound after duration
    }

    startLoopingClickSound(3);
    setTimeout(() => {
      stopLoopingClickSound();
    }, 5000);
  };

  // Play the new sounds
  const playNewSound1 = () => {
    if (audioCtx && newSoundBuffer1.current) {
      const source = audioCtx.createBufferSource();
      source.buffer = newSoundBuffer1.current;
      source.connect(audioCtx.destination);
      source.start();
      currentAudioSource.current = source;
    }
  };

  const playNewSound2 = () => {
    if (audioCtx && newSoundBuffer2.current) {
      const source = audioCtx.createBufferSource();
      source.buffer = newSoundBuffer2.current;
      source.connect(audioCtx.destination);
      source.start();
      currentAudioSource.current = source;
    }
  };

  // Function to stop the current audio source if playing
  const stopCurrentAudio = () => {
    if (currentAudioSource.current) {
      currentAudioSource.current.stop();
      currentAudioSource.current = null; // Clear the ref
    }
  };

  const playNewSound3 = () => {
    if (audioCtx && newSoundBuffer3.current) {
      const source = audioCtx.createBufferSource();
      source.buffer = newSoundBuffer3.current;
      source.connect(audioCtx.destination);
      source.start();
      currentAudioSource.current = source;
    }
  };

  const playGeneratedSound = (type, frequency, duration) => {
    generateSound(type, frequency, duration);
  };

  return (
    <AudioContext.Provider
      value={{
        playClickSound,
        startLoopingClickSound,
        stopLoopingClickSound,
        adjustLoopPlaybackRate,
        playGeneratedSound,
        playNewSound1,
        playNewSound2,
        playNewSound3,
        stopCurrentAudio,
      }}
    >
      {children}
    </AudioContext.Provider>
  );
};

// Custom hook to use audio context
export const useAudio = () => useContext(AudioContext);
