import React, { useEffect, useState, useRef, Suspense } from "react";
import Seviceoptions from "./DashboardParts/DashboardCenterBody/Serviceoptions";
import DashboardLeftSidebar from "./DashboardParts/DashboardLeftSidebarParts/DashboardLeftSidebar";
import "./Dashboard.css";
import Loader from "../CommonFolder/Loader";
import { GiCarWheel } from "react-icons/gi";
import { BsFullscreen, BsFullscreenExit } from "react-icons/bs";
import ProductData from "./DashboardParts/DashboardCenterBody/ProductData.json";
import ProductTitle from "./DashboardParts/DashboardCenterBody/ProductTitle.json";
import MyButton from "../CommonFolder/MyButton";
import { FaPlay, FaPause } from "react-icons/fa";
import { MdOutlineReplay } from "react-icons/md";
import { useVideo } from "../../VideoContext";
import DashboardRightSidebar from "./DashboardParts/DashboardRightSidebarParts/DashboardRightSidebar";
import { useAudio } from "../../AudioContext";
import axios from "axios";

const Dashboard = () => {
  const { playClickSound } = useAudio();
  const [showModal, setShowModal] = useState(false);
  const DashvideoRef = useRef(null);
  const scrollDivRef = useRef(null);
  const videoRefSecond = useRef(null);
  const showModalvideoRef = useRef(null);
  const Dealid = parseInt(window.location.pathname.split("/")[2], 10);
  const usertoken = localStorage.getItem("token");
  const [SecondModalVideo, setSecondModalVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [isFullWidth, setIsFullWidth] = useState(true);
  const ModalvideoRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState("");
  const [triviaText, setTriviaText] = useState("");
  const [productTitle, setProductTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [isDeleteActive, setIsDeleteActive] = useState(true);
  const productRefs = useRef({});
  const [activeDeleteProducts, setActiveDeleteProducts] = useState([]);
  const [ProductShowAll, SetProductShowAll] = useState(true);
  const [selectedReason, setSelectedReason] = useState("Price");
  const [menuId, setMenuId] = useState(null);
  const [Opendashboard, setOpendashboard] = useState(false);
  const [cleanedService, setCleanedService] = useState(
    selectedService.replace(/[\s/]+/g, "")
  );
  const [innerModalVideoStop, setinnerModalVideoStop] = useState(false);
  const [DealIdHas, setDealIdHas] = useState(false);
  const [dynamivVideo, setdynamivVideo] = useState("");
  const [firstVideoDuration, setFirstVideoDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const [scrollTimeoutId, setScrollTimeoutId] = useState(null);
  const [DashboardCarSrc, setDashboardCarSrc] = useState(
    "/Images/DashboardCar.png"
  );
  const [isModalVideoPlaying, setIsModalVideoPlaying] = useState(true);

  // Handler to get the duration of the first video
  const handleFirstVideoMetadata = () => {
    if (DashvideoRef.current) {
      const durationInSeconds = DashvideoRef.current.duration;
      setFirstVideoDuration(durationInSeconds);
      // console.log("durationInSeconds", durationInSeconds);

      // const RunApiTime = durationInSeconds / 2;

      // console.log("RunApiTime", RunApiTime);
      // setTimeout(() => {
      //   HandletriviaTextVideo();
      //   console.log("API function called after half video time");
      // }, RunApiTime * 1000);
    }

    // HandletriviaTextVideo();
  };

  // Function to handle video time updates and scroll the div
  const handleTimeUpdate = () => {
    if (DashvideoRef.current && firstVideoDuration) {
      const videoCurrentTime = DashvideoRef.current.currentTime;
      setCurrentTime(videoCurrentTime);
    }
  };

  // Scroll logic in a separate effect, monitoring `currentTime`
  useEffect(() => {
    if (
      scrollDivRef.current &&
      firstVideoDuration > 0 &&
      currentTime <= firstVideoDuration * 2
    ) {
      const scrollElement = scrollDivRef.current;
      const scrollHeight =
        scrollElement.scrollHeight - scrollElement.clientHeight;
      const totalDuration = firstVideoDuration;

      const scrollPosition = (currentTime / totalDuration) * scrollHeight;
      scrollElement.scrollTop = scrollPosition;
    }
  }, [currentTime, firstVideoDuration]);

  // After video ends, continue the scroll for the remaining time

  const handleVideoEnd = () => {
    // setSecondModalVideo(true);
    // const video = videoRefSecond.current;

    // if (video) {
    //   video.play();
    // }

    setIsPlaying(false);
    setSecondModalVideo(false);
    if (!Opendashboard) {
      // Find the current index of the selected service
      const currentIndex = services.findIndex(
        (service) => service.ServicesTitle === selectedService
      );

      if (currentIndex === services.length - 1) {
        console.log("Last modal reached, stopping sequence.");
        setOpendashboard(true);
        setDealIdHas(true);
        setIsFullWidth(false);

        return; // Exit the function to prevent looping back
      }

      // Calculate the next index (loop back to the first service if at the end)
      const nextIndex = currentIndex + 1;
      console.log("services.length", services.length);
      // Set the selected service to the next service title
      const nextServiceTitle = services[nextIndex].ServicesTitle;
      setSelectedService(nextServiceTitle);

      // Update the cleanedService for the next video
      const newCleanedService = nextServiceTitle.replace(/[\s/]+/g, "");
      setCleanedService(newCleanedService);

      // Update the ProductId based on the next index
      const newProductId = `Top-ProductNo${nextIndex}`; // Increment ProductId
      setSelectedProductId(newProductId);
    }
    if (!DealIdHas) {
      setIsFullWidth(true);
      setinnerModalVideoStop(false);
    }

    // Clear any existing timeout to prevent multiple timers
    if (scrollTimeoutId) {
      clearTimeout(scrollTimeoutId);
    }

    if (currentTime < firstVideoDuration) {
      setIsScrolling(true);

      // Set a timeout to start scrolling after 3 seconds
      const timeoutId = setTimeout(() => {
        const startTime = currentTime;
        const interval = setInterval(() => {
          setCurrentTime((prevTime) => {
            const newTime = prevTime + 0.1;
            if (newTime >= firstVideoDuration) {
              clearInterval(interval);
              setIsScrolling(false);
            }
            return newTime;
          });
        }, 100); // Update every 100ms
      }, 3000); // Delay for 3 seconds

      setScrollTimeoutId(timeoutId); // Store the timeout ID for potential future cleanup
    }
  };

  // Handler to get the duration of the first video finish scrolling code ====>>>>>>>>>>>
  const handleRadioChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedReason(selectedValue);
  };

  const initialServices = [
    {
      id: 1,
      WarrantyImages: "tools.svg",
      ServicesTitle: "Vehicle Service Contract",
    },
    {
      id: 2,
      WarrantyImages: "windshield.svg",
      ServicesTitle: "Windshield Protection",
    },
    {
      id: 3,
      WarrantyImages: "remote-control2.svg",
      ServicesTitle: "Key/Remote Replacement",
    },
    {
      id: 4,
      WarrantyImages: "car-crash.svg",
      ServicesTitle: "GAP",
    },
    {
      id: 5,
      WarrantyImages: "car-painting.svg",
      ServicesTitle: "Paintless Dent Repair",
    },
    {
      id: 6,
      WarrantyImages: "under-maintenance.svg",
      ServicesTitle: "Pre-paid Maintenance",
    },
    {
      id: 7,
      WarrantyImages: "anti-theft-system.svg",
      ServicesTitle: "Anti-theft",
    },
    {
      id: 8,
      WarrantyImages: "car-painting.svg",
      ServicesTitle: "Paint Protection",
    },
    {
      id: 9,
      WarrantyImages: "tierVector.svg",
      ServicesTitle: "Tire & Wheel Protection",
    },
    {
      id: 10,
      WarrantyImages: "layers.svg",
      ServicesTitle: "TotalCare Shield",
      OptionMSG: "3",
    },
  ];

  const [services, setServices] = useState([initialServices]);

  const service = services.find(
    (service) => service.ServicesTitle === selectedService
  );
  const midpoint = Math.ceil(services.length / 2);
  const topServices = services.slice(0, midpoint);
  const bottomServices = services.slice(midpoint);
  const { playPreloadedVideo, isVideoEndedDash } = useVideo();
  useEffect(() => {
    const storedDealIds =
      JSON.parse(localStorage.getItem("openedDealIds")) || [];

    if (!storedDealIds.includes(Dealid)) {
      setShowModal(true);

      storedDealIds.push(Dealid);
      localStorage.setItem("openedDealIds", JSON.stringify(storedDealIds));
    } else {
      // console.log("storedDealIds", storedDealIds);
      setOpendashboard(true);
      setDealIdHas(true);
    }
  }, [Dealid]);

  const initalMenu = [
    {
      VSC: {
        price: "00.0",
      },

      GAP: {
        price: "00.0",
      },

      PPM: {
        price: "00.0",
      },

      anti_theft: {
        price: "00.0",
      },

      PDR: {
        price: "00.0",
      },

      windshield_protection: {
        price: "00.0",
      },

      LWT: {
        price: "00.0",
      },

      key_replacement: {
        price: "00.0",
      },

      TWL: {
        price: "00.0",
      },

      paint_protection: {
        price: "00.0",
      },
      TB: {
        price: "00.0",
      },
    },
  ];

  const [menuData, setMenuData] = useState(initalMenu);
  const [emiData, setemiData] = useState(0.0);
  const [second_emiData, setsecond_emiData] = useState(0.0);
  const [interestData, setinterestData] = useState(0.0);
  const [termData, settermData] = useState(0.0);
  const [second_termData, setsecond_termData] = useState(0.0);
  const [downData, setDownData] = useState(0.0);
  const [isSidebarLoaded, setIsSidebarLoaded] = useState(false);
  const [productSalePrice, setproductSalePrice] = useState();

  const getMenu = async () => {
    const menuResponse = await fetch(
      "https://api.menuverse.ai/menu/show/" + Dealid,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    );

    const result = await menuResponse.json();
    const products = result.data?.menu;
    const emi = result.data?.emi;
    const second_emi = result.data?.second_emi;
    const interest = result.data?.interest;
    const term = result.data?.term;
    const second_term = result.data?.second_term;
    const down = result.data?.down;
    setemiData(emi);
    console.log("products", products);
    setproductSalePrice(products);
    setsecond_emiData(second_emi);
    setinterestData(interest);
    settermData(term);
    setsecond_termData(second_term);
    setDownData(down);
    if (products) {
      const updated_products = products.reduce((acc, prod) => {
        const key = Object.keys(prod)[0];
        const productDetails = Object.values(prod)[0][0]; // Extract product details
        const isEditable = prod.is_default_editable;
        acc[key] = {
          price: productDetails?.sell_price,
          name: productDetails?.name,
          miles: productDetails?.miles,
          months: productDetails?.months,
          deductible: productDetails?.deductible,
          service_interval: productDetails?.service_interval,
          tire_rotations: productDetails?.tire_rotations,
          category_type: productDetails?.category_type,
          menu_id: productDetails?.menuId,
          id: productDetails?.id,
          coupon: productDetails?.coupon,
          resp_id: productDetails?.resp_id,
          vendor: productDetails?.vendor,
          is_default_editable: isEditable,
        };
        return acc;
      }, {});

      // Remove keys from `initalMenu[0]` that are not in `updated_products`
      const keysToKeep = Object.keys(updated_products);
      const filteredMenu = Object.keys(initalMenu[0])
        .filter((key) => keysToKeep.includes(key))
        .reduce((acc, key) => {
          acc[key] = initalMenu[0][key];
          return acc;
        }, {});

      // Add the updated products to the filtered menu
      let finalMenu = {
        ...filteredMenu,
        ...updated_products,
      };

      const newServices = [];

      if (finalMenu?.VSC) {
        newServices.push({
          id: 1,
          WarrantyImages: "tools.svg",
          ServicesTitle: "Vehicle Service Contract",
        });
      }

      if (finalMenu?.windshield_protection) {
        newServices.push({
          id: 2,
          WarrantyImages: "windshield.svg",
          ServicesTitle: "Windshield Protection",
        });
      }

      if (finalMenu?.key_replacement) {
        newServices.push({
          id: 3,
          WarrantyImages: "remote-control2.svg",
          ServicesTitle: "Key/Remote Replacement",
        });
      }

      if (finalMenu?.GAP) {
        newServices.push({
          id: 4,
          WarrantyImages: "car-crash.svg",
          ServicesTitle: "GAP",
        });
      }

      if (finalMenu?.PDR) {
        newServices.push({
          id: 5,
          WarrantyImages: "car-painting.svg",
          ServicesTitle: "Paintless Dent Repair",
        });
      }

      if (finalMenu?.PPM) {
        newServices.push({
          id: 6,
          WarrantyImages: "under-maintenance.svg",
          ServicesTitle: "Pre-paid Maintenance",
        });
      }

      if (finalMenu?.anti_theft) {
        newServices.push({
          id: 7,
          WarrantyImages: "anti-theft-system.svg",
          ServicesTitle: "Anti-theft",
        });
      }

      if (finalMenu?.paint_protection) {
        newServices.push({
          id: 8,
          WarrantyImages: "car-painting.svg",
          ServicesTitle: "Paint Protection",
        });
      }

      if (finalMenu?.TWL) {
        newServices.push({
          id: 9,
          WarrantyImages: "tierVector.svg",
          ServicesTitle: "Tire & Wheel Protection",
        });
      }

      if (finalMenu?.TB) {
        newServices.push({
          id: 10,
          WarrantyImages: "layers.svg",
          ServicesTitle: "TotalCare Shield",
          OptionMSG: "3",
        });
      }
      console.log("finalMenu", finalMenu);
      setServices(newServices);
      finalMenu = {
        ...finalMenu,
        LWT: {
          price: "00.0",
        },
      };
      setMenuData(finalMenu);
    }
  };

  useEffect(() => {
    getMenu();
  }, []);

  const handleSaveMenu = () => {
    getMenu();
  };

  useEffect(() => {
    if (showModal && isSidebarLoaded) {
      const video = showModalvideoRef.current;
      if (video) {
        playPreloadedVideo("welcomToMenu", showModalvideoRef);
      }
    }
  }, [isSidebarLoaded]);

  useEffect(() => {
    if (isVideoEndedDash) {
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
    }
  }, [isVideoEndedDash]);

  const handleSidebarLoad = () => {
    setIsSidebarLoaded(true);
  };

  const handleClickClose = () => {
    setShowModal(false);
    setIsModalOpen(true);
    const video = showModalvideoRef.current;
    if (video) {
      video.pause();
    }

    setTimeout(() => {
      // Find the second button by its data-bs-target attribute
      const secondButton = document.querySelector(
        '[data-bs-target="#TotalCareShieldAndComman"]'
      );
      if (secondButton) {
        secondButton.click();
      }
    }, 40);
  };

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
    setHasError(true);
  };

  const getClassForServices = (length) => {
    switch (length) {
      case 4:
        return "Four";
      case 3:
        return "Three";
      default:
        return "";
    }
  };

  const handleClickVideoWidth = () => {
    playClickSound();
    setIsFullWidth(false);
    setSecondModalVideo(false);

    if (ModalvideoRef.current) {
      ModalvideoRef.current.pause();
      ModalvideoRef.current.currentTime = 0;
    }
  };

  const handleModalVideoEnd = () => {
    setIsFullWidth(false);
    setinnerModalVideoStop(true);
    const video = DashvideoRef.current;
    if (video) {
      video.play();
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      // Play the video and handle the Promise
      ModalvideoRef.current
        .play()
        .then(() => {
          console.log("Video started playing successfully.");
          // Additional logic if needed
        })
        .catch((error) => {
          console.error("Error playing the video:", error);
          // Handle the error (e.g., show an error message or retry)
        });
    } else {
      ModalvideoRef.current.pause();
      setIsFullWidth(true); // Reset state when modal is closed
    }
  }, [isModalOpen]);

  const videoWidth = () => {
    setIsFullWidth(!isFullWidth);
  };

  const handleVideoEnd2 = () => {
    setIsPlaying(false);
    setSecondModalVideo(false);
    if (!Opendashboard) {
      // Find the current index of the selected service
      const currentIndex = services.findIndex(
        (service) => service.ServicesTitle === selectedService
      );

      // Calculate the next index (loop back to the first service if at the end)
      const nextIndex = (currentIndex + 1) % services.length;

      // Set the selected service to the next service title
      const nextServiceTitle = services[nextIndex].ServicesTitle;
      setSelectedService(nextServiceTitle);

      // Update the cleanedService for the next video
      const newCleanedService = nextServiceTitle.replace(/[\s/]+/g, "");
      setCleanedService(newCleanedService);

      // Update the ProductId based on the next index
      const newProductId = `Top-ProductNo${nextIndex}`; // Increment ProductId
      setSelectedProductId(newProductId);
    }
    if (!DealIdHas) {
      setIsFullWidth(true);
      setinnerModalVideoStop(false);
    }
  };

  useEffect(() => {
    const handleShowModal = () => {
      setIsModalOpen(true);
    };

    const handleHideModal = () => {
      setIsModalOpen(false);
      setinnerModalVideoStop(false);
      setSelectedService("");
    };

    const modalElement = document.getElementById("TotalCareShieldAndComman");
    modalElement.addEventListener("show.bs.modal", handleShowModal);
    modalElement.addEventListener("hide.bs.modal", handleHideModal);

    return () => {
      modalElement.removeEventListener("show.bs.modal", handleShowModal);
      modalElement.removeEventListener("hide.bs.modal", handleHideModal);
    };
  }, []);

  const storedState = localStorage.getItem("state");

  useEffect(() => {
    if (selectedService) {
      const matchedProductTitle = ProductTitle.data.find(
        (item) => item.Product === selectedService
      );
      setProductTitle(
        matchedProductTitle
          ? matchedProductTitle.Title
          : "No relevant information available for the selected service."
      );
    }
  }, [selectedService]);

  useEffect(() => {
    if (storedState && selectedService) {
      const matchedItem = ProductData.data.find(
        (item) =>
          item.State === storedState &&
          item.Product.toLowerCase() === selectedService.toLowerCase()
      );
      if (matchedItem) {
        setTriviaText(matchedItem.Trivia);
        if (
          triviaText &&
          triviaText !==
            "No relevant information available for the selected service."
        ) {
          // setTimeout(() => {
          //   HandletriviaTextVideo();
          // }, 2000);
        }
      } else {
        setTriviaText(
          "No relevant information available for the selected service."
        );
      }
    } else {
      setTriviaText(
        "No relevant information available for the selected service."
      );
    }
    const newCleanedService = selectedService.replace(/[\s/]+/g, "");
    setCleanedService(newCleanedService);
  }, [selectedService, storedState, triviaText]);

  const HandletriviaTextVideo = async () => {
    // console.log("triviaText", triviaText);

    try {
      const response = await axios.post(
        "https://versa.ampleteck.com/videogeneration",
        {
          query: triviaText,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-token":
              "4$4V7r^!7{Rz3}7[=)M{f#y!&5>rTq6eS[t&kx)Uv%9*R*72;X8R*tJ,>3MBc(eW}D==]5x@qMX?84gP?kZx;T8E42h)P3G!p4#7",
          },
        }
      );
      setdynamivVideo(response.data.result_url);

      // console.log("Response:", response.data.result_url);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const playVideo = () => {
    const Fullvideo = showModalvideoRef.current;
    if (Fullvideo) {
      Fullvideo.play();
      setIsPlaying(true);
    }
  };
  const DashvideoPlayVideo = () => {
    const video = DashvideoRef.current;
    if (video) {
      video.play();
      setIsPlaying(true);
    }
  };

  const DashvideoPauseVideo = () => {
    const video = DashvideoRef.current;
    if (video) {
      video.pause();
      setIsPlaying(false);
    }
  };

  const pauseVideo = () => {
    const Fullvideo = showModalvideoRef.current;
    if (Fullvideo) {
      Fullvideo.pause();
      setIsPlaying(false);
    }
  };

  const DashvideoReplayVideo = () => {
    const video = DashvideoRef.current;
    if (video) {
      video.currentTime = 0;
      video.play();
      setIsPlaying(true);
    }
  };
  const replayVideo = () => {
    const Fullvideo = showModalvideoRef.current;
    if (Fullvideo) {
      Fullvideo.currentTime = 0;
      Fullvideo.play().catch((error) => {
        console.error("Error playing video:", error);
      });
      setIsPlaying(true);
    }
  };

  const handleLoadedData = () => {
    setLoading(false);
  };

  const handleError = () => {
    setLoading(false);
  };

  const [IsdeleteProductProceed, setDeleteProductProceed] = useState(false);

  const handleProceedClick = async () => {
    setDeleteProductProceed(true);
    playClickSound();
    // alert(menuId);
    // alert("removing");

    const removeProductResponse = await fetch(
      "https://api.menuverse.ai/menu/delete/" + menuId,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    );

    const result = await removeProductResponse.json();

    getMenu();

    playClickSound();

    const productElement = productRefs.current[selectedProductId];
    // console.log("selectedProductId", selectedProductId);
    if (productElement) {
      productElement.classList.add("DeleteActive");

      setActiveDeleteProducts((prevActiveDeleteProducts) => {
        let updatedProducts = prevActiveDeleteProducts;

        // If the selected product ID is not already in the array, add it
        if (!prevActiveDeleteProducts.includes(selectedProductId)) {
          updatedProducts = [...prevActiveDeleteProducts, selectedProductId];

          // console.log("updatedProducts", updatedProducts);
        }

        // Set ProductShowAll to false if there are products in the array, true if empty
        if (updatedProducts.length == 0) {
          SetProductShowAll(0);
        } else if (updatedProducts.length == 1) {
          SetProductShowAll(1);
        } else {
          SetProductShowAll(2);
        }

        return updatedProducts;
      });
      setDeleteProductProceed(false);

      setIsDeleteActive(false);
    }
  };

  const handleAddAllProtectionsClick = () => {
    setActiveDeleteProducts([]);
    SetProductShowAll(0);

    activeDeleteProducts.forEach((productId) => {
      const productElement = productRefs.current[productId];
      if (productElement) {
        productElement.classList.remove("DeleteActive");
      }
    });

    setIsDeleteActive(true);
  };

  const handleRemoveProduct = async (product) => {
    // alert("adding");
    // alert(product.productDbId);

    const removeProductResponse = await fetch(
      "https://api.menuverse.ai/menu/addMenuProduct/" + product.productDbId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    );

    const result = await removeProductResponse.json();

    getMenu();

    setActiveDeleteProducts((prevActiveDeleteProducts) => {
      // console.log("prevActiveDeleteProducts", prevActiveDeleteProducts);
      // const updatedProducts = prevActiveDeleteProducts.filter(
      //   (id) => id !== productId
      // );
      const updatedProducts = prevActiveDeleteProducts.filter(
        (id) => id !== product.ProductId
      );

      if (updatedProducts.length === 0) {
        setIsDeleteActive(true);
        SetProductShowAll(0);
      } else if (updatedProducts.length === 1) {
        SetProductShowAll(1);
      } else {
        SetProductShowAll(2);
      }

      return updatedProducts;
    });

    const productElement = productRefs.current[product.ProductId];
    if (productElement) {
      productElement.classList.remove("DeleteActive");
    }
  };

  const sanitizedServiceName = selectedService
    .replace(/\s+/g, "")
    .replace(/\//g, "");

  // Mapping of VIN numbers to image URLs
  const vinImageMap = {
    WMWSV3C56DT389229:
      "https://vhr.nyc3.cdn.digitaloceanspaces.com/vehiclemedia/gallery/2013/mini/hardtop/cooper-s-2dr-manual/ext-3231303031.jpg",
    "2GC4YME75P1718942":
      "https://vhr.nyc3.cdn.digitaloceanspaces.com/vehiclemedia/gallery/2023/chevrolet/silverado-3500hd/lt-4x4-crew-cab-8-ft.-box-172-in.-wb-drw-automatic/ext-3231303031.jpg",
    "1G1YB3D46R5118364":
      "https://vhr.nyc3.cdn.digitaloceanspaces.com/vehiclemedia/gallery/2024/chevrolet/corvette/e-ray-w-3lz-2dr-coupe-automatic/ext-3231303031.jpg",
    "1GCHK23KX7F566812":
      "https://vhr.nyc3.cdn.digitaloceanspaces.com/vehiclemedia/gallery/2007/chevrolet/silverado-2500hd/lt2-4x4-crew-cab-8-ft.-box-167-in.-wb-automatic/ext-6130313031.jpg",
    "2C3KA53G96H355723":
      "https://vhr.nyc3.cdn.digitaloceanspaces.com/vehiclemedia/gallery/2006/chrysler/300/touring-4dr-rear-wheel-drive-sedan-automatic/ext-6230313031.jpg",
  };

  useEffect(() => {
    const dealUserVinNumber = localStorage.getItem("DealUservinNumber");
    // Set image based on VIN number or use default if not found
    setDashboardCarSrc(
      vinImageMap[dealUserVinNumber] || "/Images/red-car-aimage.png"
    );
  }, []);

  // product modal video play pause video button functions

  const ProductModalPlayVideo = () => {
    const video = ModalvideoRef.current;
    if (video) {
      video.play().catch((error) => {
        console.error("Error playing video:", error);
      });
      setIsModalVideoPlaying(true);
      setinnerModalVideoStop(false);
    }
  };

  const ProductModalReplayVideo = () => {
    const video = ModalvideoRef.current;
    if (video) {
      video.currentTime = 0;
      video.play().catch((error) => {
        console.error("Error playing video:", error);
      });
      setIsModalVideoPlaying(true);
    }
  };

  const ProductModalPauseVideo = () => {
    const video = ModalvideoRef.current;
    if (video) {
      video.pause();
      setIsModalVideoPlaying(false);
    }
  };

  const [isModalvideoRefLoaded, setModalvideoRefLoaded] = useState(true);

  const handleModalvideoRefLoaded = () => {
    setModalvideoRefLoaded(false);
  };

  return (
    <>
      <section id="Dashboard">
        <div className="container-fluid">
          <div className="row">
            <DashboardLeftSidebar
              menuData={menuData}
              productSalePrice={productSalePrice}
              emiData={emiData}
              second_emiData={second_emiData}
              interestData={interestData}
              termData={termData}
              second_termData={second_termData}
              downData={downData}
              onSaveMenu={handleSaveMenu}
              ProductShowAll={ProductShowAll}
            />
            <div className="col-lg-6 py-4 Dashboard-Center-Section">
              <button
                onClick={handleAddAllProtectionsClick}
                className="Add-All-Protections-Btn"
                style={{ display: isDeleteActive ? "none" : "block" }}
              >
                Add all protections
              </button>
              <div
                id={topServices.length === 5 ? "SeviceoptionsTop" : ""}
                className={`Seviceoptions TopOptions ${getClassForServices(
                  topServices.length
                )}`}
              >
                {topServices.map((service, index) => (
                  <Seviceoptions
                    key={index}
                    ProductId={`Top-ProductNo${index}`}
                    WarrantyImages={service.WarrantyImages}
                    ServicesTitle={service.ServicesTitle}
                    OptionMSG={service.OptionMSG}
                    onServiceClick={setSelectedService}
                    ondltproductClick={setSelectedProductId}
                    ref={(el) =>
                      (productRefs.current[`Top-ProductNo${index}`] = el)
                    }
                    menuData={menuData}
                    onClickProduct={(e) => {
                      console.log("NewMENUProduct:", e);
                      setMenuId(e);
                      // alert("clike");
                    }}
                    onRemoveProduct={handleRemoveProduct}
                    HandletriviaTextVideo={HandletriviaTextVideo}
                  />
                ))}
              </div>
              <div className="Dashboard-Red-Car position-relative">
                {IsdeleteProductProceed && <Loader />}

                {isLoading && (
                  <div className="TLoader">
                    <GiCarWheel />
                    <div className="road"></div>
                  </div>
                )}
                {!hasError && (
                  <img
                    src={DashboardCarSrc}
                    alt="Dashboard Car"
                    onLoad={handleImageLoad}
                    onError={handleImageError}
                    style={{
                      display: isLoading ? "none" : "inline-block",
                      marginTop: services.length === 10 ? "-4rem" : "",
                      marginBottom: services.length !== 10 ? "2rem" : "",
                    }}
                  />
                )}
              </div>
              <div
                id={bottomServices.length === 5 ? "SeviceoptionsBottom" : ""}
                className={`Seviceoptions bottomOptions ${getClassForServices(
                  bottomServices.length
                )}`}
              >
                {bottomServices.map((service, index) => (
                  <Seviceoptions
                    key={index}
                    ProductId={`Bottom-ProductNo${index}`}
                    WarrantyImages={service.WarrantyImages}
                    ServicesTitle={service.ServicesTitle}
                    OptionMSG={service.OptionMSG}
                    onServiceClick={setSelectedService}
                    ondltproductClick={setSelectedProductId}
                    ref={(el) =>
                      (productRefs.current[`Bottom-ProductNo${index}`] = el)
                    }
                    menuData={menuData}
                    onClickProduct={(e) => {
                      console.log("NewMENUProduct:", e);
                      setMenuId(e);
                      // alert("clike");
                    }}
                    onRemoveProduct={handleRemoveProduct}
                    HandletriviaTextVideo={HandletriviaTextVideo}
                  />
                ))}
              </div>
            </div>
            <DashboardRightSidebar
              onSidebarLoad={handleSidebarLoad}
              isModalOpen={isModalOpen}
            />
          </div>
        </div>
      </section>
      {/*========== here is start AddDealModal ========== */}

      <div
        className={`modal ${showModal ? "show" : ""}`}
        id="AddDealModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog Waiting_pop">
          <div className="modal-content border-0 bg-transparent">
            <div className="DetailVerification_Versa_Bottons">
              {isPlaying ? (
                <button onClick={pauseVideo}>
                  <FaPause />
                </button>
              ) : (
                <button onClick={playVideo}>
                  <FaPlay />
                </button>
              )}
              <button onClick={replayVideo}>
                <MdOutlineReplay />
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="background-video position-static Dashboard_Modal_Video">
                <div>
                  <video
                    className="w-100 Double_Videos"
                    id="myVideo"
                    ref={showModalvideoRef}
                  ></video>
                  <img
                    className="DashboardVersa-ImgFor-videoBg"
                    src="/assets/Images/DashboardImages/newversaNew.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer border-0 justify-content-center pb-0">
              <button className="Secondry_Btn" onClick={handleClickClose}>
                Continue
              </button>
              <button
                className="d-none"
                data-bs-toggle="modal"
                data-bs-target="#TotalCareShieldAndComman"
              >
                hide
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*========== here is start TotalCareShieldAndComman ========== */}

      <div
        className="modal"
        id="TotalCareShieldAndComman"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg DashboardModals TotalCareShieldAndComman">
          <div className="modal-content p-0">
            <div className="modal-header m-2">
              <div className="d-flex gap-2 align-items-center">
                <img
                  className=""
                  src={`/assets/Images/DashboardImages/${
                    service ? service.WarrantyImages : "default.png"
                  }`}
                  alt={selectedService}
                />

                <h5 className="modal-title">{selectedService}</h5>
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClickVideoWidth}
              ></button>
            </div>
            <div className="modal-body px-4">
              <div className="container-fluid">
                <div className="row">
                  <div
                    className={`col-md-12 p-0 LeftModalContent ${
                      isFullWidth ? "col-lg-12 order-2" : "col-lg-6"
                    }`}
                  >
                    <div className="LeftModal-Scrollar" ref={scrollDivRef}>
                      <p>{productTitle}</p>
                      {/* <p>{triviaText}</p> */}
                    </div>
                  </div>
                  <div
                    className={`col-md-12 p-0 RightModalContent position-relative ${
                      isFullWidth ? "col-lg-12 order-1" : "col-lg-6"
                    }`}
                  >
                    <div className="video-Fullscreen_Bottons">
                      {!innerModalVideoStop ? (
                        isModalVideoPlaying ? (
                          <button onClick={ProductModalPauseVideo}>
                            <FaPause />
                          </button>
                        ) : (
                          <button onClick={ProductModalPlayVideo}>
                            <FaPlay />
                          </button>
                        )
                      ) : isPlaying ? (
                        <button onClick={DashvideoPauseVideo}>
                          <FaPause />
                        </button>
                      ) : (
                        <button onClick={DashvideoPlayVideo}>
                          <FaPlay />
                        </button>
                      )}
                      <button
                        onClick={
                          innerModalVideoStop
                            ? DashvideoReplayVideo
                            : ProductModalReplayVideo
                        }
                      >
                        <MdOutlineReplay />
                      </button>
                    </div>
                    <div className="video-Fullscreen-box">
                      {isModalvideoRefLoaded && <Loader />}
                      <video
                        ref={ModalvideoRef}
                        onEnded={handleModalVideoEnd}
                        src={`/assets/Videos/DashboardModalVideo/${sanitizedServiceName}.webm`}
                        preload="none"
                        autoPlay
                        onLoadedData={handleModalvideoRefLoaded}
                      />
                      <span
                        className="video-Fullscreen-button"
                        onClick={videoWidth}
                      >
                        {isFullWidth ? <BsFullscreenExit /> : <BsFullscreen />}
                      </span>
                    </div>
                    {innerModalVideoStop && (
                      <div className="Dashboard-product-modal-videos Dashboard-outer-video-in-Product-modal">
                        <div className="Bot_Buttons Big-size-background-Ai-video">
                          {loading && <Loader />}

                          <video
                            key={cleanedService}
                            ref={DashvideoRef}
                            autoPlay
                            onLoadedData={handleLoadedData}
                            onError={handleError}
                            onEnded={handleVideoEnd}
                            onLoadedMetadata={handleFirstVideoMetadata}
                            onTimeUpdate={handleTimeUpdate}
                            style={{ zIndex: SecondModalVideo ? "1" : "2" }}
                            preload="none"
                          >
                            <source
                              src={`../assets/Videos/DashboardProductVideo/${cleanedService}.webm`}
                              type="video/webm"
                            />
                          </video>

                          {/* <video style={{ zIndex: SecondModalVideo ? "3" : "1" }} className="w-100 Double_Videos" id="myVideo" ref={videoRefSecond} onEnded={handleVideoEnd2} preload="none">
                            <source src={dynamivVideo} type="video/webm" />
                          </video> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer mx-2 py-4 gap-2 justify-content-center flex-md-nowrap">
              <MyButton
                className="w-100 Danger-Button"
                title="Remove it, I want to Risk my car"
                data-bs-toggle="modal"
                data-bs-target="#WhatReason"
              ></MyButton>
              <MyButton
                className="w-100 MyBtn"
                title="Keep it, I want to Protect my car"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></MyButton>
            </div>
          </div>
        </div>
      </div>

      {/*========== here is start WhatReason ========== */}

      <div
        className="modal"
        id="WhatReason"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog DashboardModals">
          <div className="modal-content border-0 WhatReason">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={playClickSound}
              ></button>
              <h4>What’s the reason you don’t want to buy this product?</h4>
            </div>
            <div className="modal-body p-0 text-center">
              <div className="Select_Reason">
                <div>
                  <input
                    type="radio"
                    name="RiskReason"
                    value="Price"
                    className="form-check-input"
                    onChange={handleRadioChange}
                    checked={selectedReason === "Price"}
                  />
                  <label>Price</label>
                </div>

                <span>You still have discount coupons</span>
              </div>
              <div className="Select_Reason">
                <div>
                  <input
                    type="radio"
                    name="RiskReason"
                    value="Coverage"
                    className="form-check-input"
                    onChange={handleRadioChange}
                    checked={selectedReason === "Coverage"}
                  />
                  <label>Coverage</label>
                </div>
              </div>
              <div className="Select_Reason">
                <div>
                  <input
                    type="radio"
                    name="RiskReason"
                    value="Other"
                    className="form-check-input"
                    onChange={handleRadioChange}
                    checked={selectedReason === "Other"}
                  />
                  <label>Other</label>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0 justify-content-center p-0">
              <button
                className="w-100 MyBtn"
                data-bs-toggle="modal"
                data-bs-target="#Vehicle_Risk"
                disabled={!selectedReason}
                onClick={() => setSelectedReason("Price")}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*========== here is start Vehicle_Risk ========== */}

      <div
        className="modal"
        id="Vehicle_Risk"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog DashboardModals">
          <div className="modal-content border-0 ">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={playClickSound}
            ></button>
            <div className="modal-body p-0 text-center ">
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="60"
                  viewBox="0 0 45 46"
                  fill="none"
                >
                  <g clipPath="url(#clip0_347_3959)">
                    <path
                      d="M44.3399 33.9417L27.1066 4.93834C26.2449 3.47167 24.7233 2.59167 22.9999 2.59167C21.2766 2.59167 19.7549 3.47167 18.9116 4.93834L1.65992 33.9417C0.798255 35.445 0.798255 37.2233 1.65992 38.7083C2.52159 40.1933 4.06159 41.0917 5.76659 41.0917H40.2516C41.9749 41.0917 43.5149 40.1933 44.3583 38.7083C45.2016 37.2233 45.2016 35.445 44.3399 33.9417ZM24.8333 33.7583H21.1666V30.0917H24.8333V33.7583ZM24.8333 26.425H21.1666V15.425H24.8333V26.425Z"
                      fill="#E0C22D"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_347_3959">
                      <rect
                        width="45"
                        height="45"
                        fill="white"
                        transform="translate(0 0.758301)"
                      ></rect>
                    </clipPath>
                  </defs>
                </svg>
                <h4>You’re vehicle is at risk </h4>
                <p>
                  Based on your choice to decline, you may have a potential
                  ownership lifetime exposure of $3768.{" "}
                </p>
              </>
            </div>
            <div className="modal-footer border-0 justify-content-center p-0">
              <MyButton
                className="Empty_Btn border-0"
                title="Cancel"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></MyButton>
              <button
                className="MyBtn"
                onClick={handleProceedClick}
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
