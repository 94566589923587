import React, { useState, useEffect, useRef } from "react";
import TableHeader from "../CommonFolder/DealsTableStructure/TableHeader";
import Modal from "../Deals/Modal";
import MyButton from "../CommonFolder/MyButton";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import InputFiled from "../CommonFolder/FormStructure/InputField";
import SelectField from "../CommonFolder/FormStructure/SelectField";
import { toast } from "react-toastify";
import { FaPlay, FaPause } from "react-icons/fa";
import { MdOutlineReplay } from "react-icons/md";
import { useVideo } from "../../VideoContext";
import validationRules from "../CommonFolder/FormStructure/validationRules.json";
import { useAudio } from "../../AudioContext";
import axios from "axios";

const EditDeal = ({ setIsLogin }) => {
  let half = "mb-3 DisabledInputs";
  const { id } = useParams();
  const [isPlaying, setIsPlaying] = useState(true);
  const usertoken = localStorage.getItem("token");
  const navigate = useNavigate();
  const { playPreloadedVideo, isVideoEndedEditDeal } = useVideo();
  const { playClickSound } = useAudio();
  const [stateData, setstateData] = useState([]);
  const [cityData, setcityData] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]); // Filtered city list based on state selection

  const [dealData, setDealData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    streetAddress: "",
    city: 0,
    state: 0,
    zipCode: "",
    vehicleColor: "",
    free_add_on: "",
    taxes: "",
    deal_number: "",
    loan_term: "",
    purchase_price: "",
    interest_rate: "",
    down_payment: "",
    vehicle_payment: "",
    net_trade: "",
    protection_items: "",
    rebate: "",
    total_monthly_payment: "",
    vinNumber: "",
  });

  const validateForm = () => {
    // console.log("dealData", dealData);
    const newErrors = {};

    for (const [field, rules] of Object.entries(validationRules)) {
      if (dealData[field] !== undefined) {
        const value = dealData[field];

        // Only validate if the field is required or has a value
        if (rules.required && !value) {
          newErrors[field] = rules.messages.required;
        } else if (value) {
          // Validate other rules if the field has a value
          if (rules.minLength && value.length <= rules.minLength) {
            console.log("one");
            newErrors[field] = rules.messages.minLength;
          } else if (rules.maxLength && value.length > rules.maxLength) {
            console.log("two");
            newErrors[field] = rules.messages.maxLength;
          } else if (rules.pattern && !new RegExp(rules.pattern).test(value)) {
            console.log("three");
            newErrors[field] = rules.messages.pattern;
          } else if (rules.length && value.length !== rules.length) {
            console.log("four");
            newErrors[field] = rules.messages.length;
          }
        }
      }
    }

    // Check if select fields are selected
    if (!dealData.city) newErrors.city = "Please select a city.";
    if (!dealData.state) newErrors.state = "Please select a state.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://api.menuverse.ai/newdeal/show/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + usertoken,
          },
        }
      );
      if (!response.ok) {
        localStorage.clear();
        if (response.status === 401 || response.status === 403) {
          setIsLogin(false);
          navigate("/login");
        }
        throw new Error(`Error: ${response.status}`);
      }
      const data = await response.json();
      setDealData(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleLoadedData = (videoSrc, ref) => {
    playPreloadedVideo(videoSrc, ref);
  };

  const [errors, setErrors] = useState({});

  // Filter cities whenever the state in dealData changes
  useEffect(() => {
    if (dealData.state) {
      const matchingCities = cityData.filter(
        (city) => city.state_id === Number(dealData.state)
      );
      if (matchingCities.length > 0) {
        setFilteredCities(matchingCities);
        console.log("Cities matched:", matchingCities);
      } else {
        setFilteredCities([
          {
            id: 1,
            name: "",
          },
        ]);
        console.log("No matching cities found");
      }
    }
  }, [dealData.state, cityData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Only allow numbers in InputType="tel"
    const numericValue = value.replace(/\D/g, "");
    if (e.target.type === "tel") {
      setDealData({
        ...dealData,
        [name]: numericValue,
      });
    } else if (name === "state") {
      const stateValue = Number(numericValue); // Convert state value to number
      setDealData({
        ...dealData,
        [name]: stateValue,
      });
    } else {
      setDealData({
        ...dealData,
        [name]: value,
      });
    }
  };

  const handleSave = async () => {
    if (!validateForm()) {
      toast.dismiss();
      toast.warn("Failed to add the deal. Check details.");
      return;
    }

    // If validation passes, proceed with the API call
    try {
      const response = await fetch(
        `https://api.menuverse.ai/newdeal/update/${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + usertoken,
          },
          body: JSON.stringify(dealData),
        }
      );

      if (!response.ok) {
        localStorage.clear();
        if (response.status === 401 || response.status === 403) {
          setIsLogin(false);
          navigate("/login");
        }
        throw new Error(`Error: ${response.status}`);
      }

      const result = await response.json();
      toast.dismiss();
      toast.success("Your deal has been updated successfully!");
    } catch (error) {
      console.error("Error updating data:", error);
    }
    navigate(`/DetailVerification/${id}`);
  };

  const videoRef = useRef(null);

  const playVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.play();
      setIsPlaying(true);
    }
  };

  const pauseVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.pause();
      setIsPlaying(false);
    }
  };

  const replayVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.currentTime = 0;
      video.play();
      setIsPlaying(true);
    }
  };

  const togglePlay = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    if (isVideoEndedEditDeal) {
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
    }
  }, [isVideoEndedEditDeal]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.menuverse.ai/states/show",
          {
            headers: {
              Authorization: `Bearer ${usertoken}`,
            },
          }
        );
        setstateData(response.data.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.menuverse.ai/cities/show",
          {
            headers: {
              Authorization: `Bearer ${usertoken}`,
            },
          }
        );
        setcityData(response.data.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <section className="Body_Section">
        <div className="container-fluid px-lg-20">
          <div className="row justify-content-center ">
            <div className="col-lg-9">
              <div className="Full_Table">
                <TableHeader title="ABC Dealer Group" Monthlyfinance={true} />

                <div className="container Add_Dealer Edit-Deal-container">
                  <div className="row row-cols-lg-2 row-cols-md-1">
                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="text"
                        InputName="firstName"
                        lableValue="First name"
                        required
                        value={dealData.firstName}
                        onChange={handleChange}
                        errorMessage={errors.firstName}
                      />
                      {errors.firstName && (
                        <p className="Edit-deal-error-text">
                          {errors.firstName}
                        </p>
                      )}
                    </div>

                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="text"
                        InputName="middleName"
                        lableValue="Middle Name"
                        value={dealData.middleName}
                        onChange={handleChange}
                        errorMessage={errors.middleName}
                      />
                      {errors.middleName && (
                        <p className="Edit-deal-error-text">
                          {errors.middleName}
                        </p>
                      )}
                    </div>

                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="text"
                        InputName="lastName"
                        lableValue="Last name"
                        required
                        value={dealData.lastName}
                        onChange={handleChange}
                        errorMessage={errors.lastName}
                      />
                      {errors.lastName && (
                        <p className="Edit-deal-error-text">
                          {errors.lastName}
                        </p>
                      )}
                    </div>

                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="tel"
                        InputName="phoneNumber"
                        lableValue="Phone number"
                        InputID={"phoneNumber"}
                        required
                        value={dealData.phoneNumber}
                        onChange={handleChange}
                        maxlength="10"
                        errorMessage={errors.phoneNumber}
                      />
                      {errors.phoneNumber && (
                        <p className="Edit-deal-error-text">
                          {errors.phoneNumber}
                        </p>
                      )}
                    </div>
                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="email"
                        InputName="email"
                        lableValue="Email"
                        required
                        value={dealData.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <p className="Edit-deal-error-text">{errors.email}</p>
                      )}
                    </div>

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="text"
                      InputName="streetAddress"
                      lableValue="Street Address"
                      required
                      value={dealData.streetAddress}
                      onChange={handleChange}
                    />

                    {/* State */}
                    <SelectField
                      className={half}
                      InputName="state"
                      Selectlabel="State"
                      value={dealData.state}
                      onChange={handleChange}
                      options={stateData.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                    />

                    {/* City */}
                    <SelectField
                      className={half}
                      InputName="city"
                      Selectlabel="City"
                      value={dealData.city}
                      onChange={handleChange}
                      options={filteredCities.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                    />

                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="tel"
                        InputID={"zipCode"}
                        InputName="zipCode"
                        lableValue="Zip code"
                        required
                        minlength="5"
                        maxlength="5"
                        value={dealData.zipCode}
                        onChange={handleChange}
                        errorMessage={errors.zipCode}
                      />
                      {errors.zipCode && (
                        <p className="Edit-deal-error-text">{errors.zipCode}</p>
                      )}
                    </div>

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="text"
                      InputName="vehicleColor"
                      lableValue="Vehicle color"
                      disabled
                      value={dealData.vehicleColor}
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="tel"
                      InputName="VINnumber"
                      lableValue="VIN number"
                      disabled
                      value={dealData.vinNumber}
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="tel"
                      InputName="FeeAddons"
                      lableValue="Fee + Add ons"
                      disabled
                      value={
                        dealData.free_add_on
                          ? `$    |    ${dealData.free_add_on}`
                          : ""
                      }
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="text"
                      InputName="Vehicle"
                      lableValue="Vehicle"
                      disabled
                      value={
                        dealData.vehicle ? `$    |    ${dealData.vehicle}` : ""
                      }
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="tel"
                      InputName="Taxes"
                      lableValue="Taxes"
                      disabled
                      value={
                        dealData.taxes ? `$    |    ${dealData.taxes}` : ""
                      }
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="tel"
                      InputName="Dealnumber"
                      lableValue="Deal number"
                      disabled
                      value={
                        dealData.deal_number
                          ? `$    |    ${dealData.deal_number}`
                          : ""
                      }
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="text"
                      InputName="Loanterm"
                      lableValue="Loan term"
                      disabled
                      value={
                        dealData.deal_number
                          ? `$    |    ${dealData.deal_number}`
                          : ""
                      }
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="tel"
                      InputName="Purchaseprice"
                      lableValue="Purchase price"
                      disabled
                      value={
                        dealData.purchase_price
                          ? `$    |    ${dealData.purchase_price}`
                          : ""
                      }
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="tel"
                      InputName="Interestrate"
                      lableValue="Interest rate"
                      disabled
                      value={
                        dealData.interest_rate
                          ? `$    |    ${dealData.interest_rate}`
                          : ""
                      }
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="tel"
                      InputName="Downpayment"
                      lableValue="Down payment"
                      disabled
                      value={
                        dealData.down_payment
                          ? `$    |    ${dealData.down_payment}`
                          : ""
                      }
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="tel"
                      InputName="Vehiclepayment"
                      lableValue="Vehicle payment"
                      disabled
                      value={
                        dealData.vehicle_payment
                          ? `$    |    ${dealData.vehicle_payment}`
                          : ""
                      }
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="tel"
                      InputName="Nettrade"
                      lableValue="Net trade"
                      disabled
                      value={
                        dealData.net_trade
                          ? `$    |    ${dealData.net_trade}`
                          : ""
                      }
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="tel"
                      InputName="Protectionitems"
                      lableValue="Protection items"
                      disabled
                      value={
                        dealData.protection_items
                          ? `$    |    ${dealData.protection_items}`
                          : ""
                      }
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="tel"
                      InputName="Rebate"
                      lableValue="Rebate"
                      disabled
                      value={
                        dealData.rebate ? `$    |    ${dealData.rebate}` : ""
                      }
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="tel"
                      InputName="Totalmonthlypayment"
                      lableValue="Total monthly payment"
                      disabled
                      value={
                        dealData.total_monthly_payment
                          ? `$    |    ${dealData.total_monthly_payment}`
                          : ""
                      }
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="Table_foot ">
                  <div className="Foot_btn pe-5 me-5">
                    <NavLink to={`/DetailVerification/${id}`}>
                      <MyButton title="Cancel" className="Secondry_Btn" />
                    </NavLink>
                    <MyButton
                      title="Save & Change"
                      data-bs-toggle="modal"
                      data-bs-target="#DealConfirmation"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal />

        {/*========== here is start shared Successfully!  ========== */}

        <div
          className="modal"
          id="DealConfirmation"
          data-bs-backdrop="static"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog Waiting_pop Changed_Successfully">
            <div className="modal-content border-0 ">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={playClickSound}
              ></button>
              <div className="modal-body p-0 text-center ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="60"
                  viewBox="0 0 45 46"
                  fill="none"
                >
                  <g clipPath="url(#clip0_347_3959)">
                    <path
                      d="M44.3399 33.9417L27.1066 4.93834C26.2449 3.47167 24.7233 2.59167 22.9999 2.59167C21.2766 2.59167 19.7549 3.47167 18.9116 4.93834L1.65992 33.9417C0.798255 35.445 0.798255 37.2233 1.65992 38.7083C2.52159 40.1933 4.06159 41.0917 5.76659 41.0917H40.2516C41.9749 41.0917 43.5149 40.1933 44.3583 38.7083C45.2016 37.2233 45.2016 35.445 44.3399 33.9417ZM24.8333 33.7583H21.1666V30.0917H24.8333V33.7583ZM24.8333 26.425H21.1666V15.425H24.8333V26.425Z"
                      fill="#E0C22D"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_347_3959">
                      <rect
                        width="45"
                        height="45"
                        fill="white"
                        transform="translate(0 0.758301)"
                      ></rect>
                    </clipPath>
                  </defs>
                </svg>
                <h4>
                  Are you sure you want to <br /> change the details?
                </h4>
              </div>
              <div className="modal-footer border-0 justify-content-center p-0">
                <MyButton
                  title="NO"
                  className="Secondry_Btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />

                <button
                  className="MyBtn"
                  onClick={() => {
                    handleSave();
                    playClickSound();
                  }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  yes
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="DetailVerification_Versa">
          <div className="DetailVerification_Versa_Bottons">
            {isPlaying ? (
              <button onClick={pauseVideo}>
                <FaPause />
              </button>
            ) : (
              <button onClick={playVideo}>
                <FaPlay />
              </button>
            )}
            <button onClick={replayVideo}>
              <MdOutlineReplay />
            </button>
          </div>
          <div className="Bot_Buttons Big-size-background-Ai-video">
            <video ref={videoRef} onClick={togglePlay} autoPlay unmuted></video>
            <img
              onClick={togglePlay}
              className="Big-size-background-Ai"
              src="/assets/Images/DashboardImages/newversaNew.jpg"
              alt=""
              onLoad={() => {
                handleLoadedData("dealEditVideoSrc", videoRef);
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default EditDeal;
