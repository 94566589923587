import React, { useState, useRef, useEffect } from "react";
import "../../Dashboard.css";
import ProductItems from "./ProductItems";
import { useParams } from "react-router-dom";
import TermAmountCalulation from "./TermAmountCalulation";
import MyButton from "../../../CommonFolder/MyButton";
import SignatureCanvas from "react-signature-canvas";
import InputFiled from "../../../CommonFolder/FormStructure/InputField";
import { useAudio } from "../../../../AudioContext";
import axios from "axios";
import validationRules from "../../../CommonFolder/FormStructure/validationRules.json";
import { PDFDocument, rgb } from "pdf-lib";
import { toast } from "react-toastify";
import BookLoader from "../../../CommonFolder/BookLoader";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import JSZip from "jszip";
import { FaShareAlt } from "react-icons/fa";

const DashboardLeftSidebar = ({
  menuData,
  emiData,
  second_emiData,
  interestData,
  termData,
  second_termData,
  downData,
  onSaveMenu,
  ProductShowAll,
  productSalePrice,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [InputChecked, setInputChecked] = useState(false);
  const [Pdfloading, setPdfLoading] = useState(false);

  const [contractPdfs, setContractPdfs] = useState([]);
  const sigCanvas = useRef({});
  const { playClickSound } = useAudio();
  const [isSignaturePresent, setIsSignaturePresent] = useState(false);
  const fileInputRef = useRef(null);
  const [signatureImage, setSignatureImage] = useState(null);
  const [isCanvasDisabled, setIsCanvasDisabled] = useState(true);
  const [uploadedSignatureImage, setUploadedSignatureImage] = useState(false);
  const [SharePolicieDisable, setSharePolicieDisable] = useState(true);
  const [email, setEmail] = useState();
  const [ContractLoading, setContractLoading] = useState(true);
  const [recommendedServices, setRecommendedServices] = useState([]);
  const { id } = useParams();
  const [errors, setErrors] = useState({});
  const usertoken = localStorage.getItem("token");
  const [DownloadZip, setDownloadZip] = useState(false);
  const [downloadingIndex, setDownloadingIndex] = useState(null);
  // const [ProductsTotalSellPrice, setProductsTotalSellPrice] = useState(0);

  if (Array.isArray(menuData)) {
    menuData = menuData[0];
  }

  useEffect(() => {
    console.log("menuData", menuData);
  }, [menuData]);
  // click sound function *********

  const Dealid = parseInt(window.location.pathname.split("/")[2], 10);

  const handleContractSubmit = async () => {
    setPdfLoading(true);
    const data = {
      deal_id: Dealid,
    };
    const menuResponse = await fetch(
      "https://api.menuverse.ai/fni_products/sent_contract",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
        body: JSON.stringify(data),
      }
    );

    if (menuResponse.status) {
      const menuResponseJson = await menuResponse.json();

      const submittedProducts =
        menuResponseJson.data.EX1ContractResponse?.Products?.Product;

      if (submittedProducts) {
        setContractPdfs(submittedProducts);
        setPdfLoading(false);
        setContractLoading(false);
      }
      console.log(
        "EX1ContractResponse",
        menuResponseJson.data.EX1ContractResponse?.Products?.Product
      );
      // const menuData = menuResponseJson.data;
      // onSaveMenu();
    }
  };

  // handlePdf download pdf one by one click on view button

  const handlePdfDownload = async (ContractNumber, ProductCode, index) => {
    setDownloadingIndex(index);
    const data = {
      deal_id: Dealid,
      contract_number: ContractNumber,
      product_code: ProductCode,
    };

    const menuResponse = await fetch(
      "https://api.menuverse.ai/fni_products/get_pdf",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
        body: JSON.stringify(data),
      }
    );

    if (menuResponse.ok) {
      const menuResponseJson = await menuResponse.json();
      const pdfUrl =
        menuResponseJson.data.EX1FormResponse?.Contracts?.Product?.ContractForm;

      const pagesData = Array.isArray(
        menuResponseJson.data.EX1FormResponse?.Contracts?.Product?.SigCoords
          ?.Pages?.Page
      )
        ? menuResponseJson.data.EX1FormResponse?.Contracts?.Product?.SigCoords
            ?.Pages?.Page
        : [
            menuResponseJson.data.EX1FormResponse?.Contracts?.Product?.SigCoords
              ?.Pages?.Page,
          ];

      if (pdfUrl && signatureImage) {
        try {
          const pdfBytes = await fetch(pdfUrl).then((res) => res.arrayBuffer());
          const pdfDoc = await PDFDocument.load(pdfBytes);
          const totalPages = pdfDoc.getPageCount();
          const base64Data = signatureImage.replace(
            /^data:image\/\w+;base64,/,
            ""
          );
          const signatureImageBytes = Uint8Array.from(atob(base64Data), (c) =>
            c.charCodeAt(0)
          );
          const signatureImageEmbed = await pdfDoc.embedPng(
            signatureImageBytes
          );

          // Loop through each page data and apply the signature
          for (const pageData of pagesData) {
            const PageNo = Number(pageData?.PageNo);
            const pageIndex = Math.min(Math.max(PageNo - 1, 0), totalPages - 1);
            const selectedPage = pdfDoc.getPage(pageIndex);
            const PageHeight = Number(pageData?.PageHeight);

            // Loop through each Coord on the page and add the signature
            for (const coord of pageData?.Coords?.Coord || []) {
              const xPosition = Number(coord?.XPosition);
              const yPosition = Number(coord?.YPosition);
              const SignWidth = Number(coord?.Width);
              const SignHeight = Number(coord?.Height);
              const Pyposition = (PageHeight - yPosition) * 3 - 33 + 15;
              const Pxposition = xPosition * 3;
              // const SignType = coord?.SigType;
              // console.log("xPosition", xPosition);
              // console.log("yPosition", yPosition);
              // console.log("SignWidth", SignWidth);
              // console.log("SignHeight", SignHeight);
              console.log("Pyposition", Pyposition);
              console.log("Pxposition", Pxposition);
              // console.log("SignType", SignType);

              // Draw the signature for each coordinate
              // if (SignType == "COCUSTOMER_SIGNATURE" || SignType == "CUSTOMER_SIGNATURE") {
              selectedPage.drawImage(signatureImageEmbed, {
                x: Pxposition,
                y: Pyposition,
                width: SignWidth,
                height: SignHeight * 3,
              });
              // }
            }
          }

          const pdfWithSignatureBytes = await pdfDoc.save();
          const blob = new Blob([pdfWithSignatureBytes], {
            type: "application/pdf",
          });

          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "signed_contract.pdf";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setDownloadingIndex(null);
        } catch (error) {
          toast.dismiss();
          toast.warn("Failed to process the PDF");
        }
      } else {
        toast.dismiss();
        toast.warn("PDF URL or signature image not found");
      }
    } else {
      toast.dismiss();
      toast.warn("Failed to process the PDF");
    }
  };

  // handle Download Zip click on download zip button click

  const handleDownloadZip = async () => {
    setDownloadZip(true);
    try {
      const zip = new JSZip();
      const folder = zip.folder("pdfs"); // Create a folder inside the zip for PDFs

      // Loop through all PDFs and process them
      for (const pdf of contractPdfs) {
        const { ContractNumber, Product } = pdf;
        const { ProductCode } = Product;

        const data = {
          deal_id: Dealid,
          contract_number: ContractNumber,
          product_code: ProductCode,
        };

        const response = await fetch(
          "https://api.menuverse.ai/fni_products/get_pdf",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + usertoken,
            },
            body: JSON.stringify(data),
          }
        );

        if (response.ok) {
          const responseJson = await response.json();
          const pdfUrl =
            responseJson.data.EX1FormResponse?.Contracts?.Product?.ContractForm;
          const pagesData = Array.isArray(
            responseJson.data.EX1FormResponse?.Contracts?.Product?.SigCoords
              ?.Pages?.Page
          )
            ? responseJson.data.EX1FormResponse?.Contracts?.Product?.SigCoords
                ?.Pages?.Page
            : [
                responseJson.data.EX1FormResponse?.Contracts?.Product?.SigCoords
                  ?.Pages?.Page,
              ];

          if (pdfUrl && signatureImage) {
            const pdfBytes = await fetch(pdfUrl).then((res) =>
              res.arrayBuffer()
            );
            const pdfDoc = await PDFDocument.load(pdfBytes);
            const totalPages = pdfDoc.getPageCount();
            const base64Data = signatureImage.replace(
              /^data:image\/\w+;base64,/,
              ""
            );
            const signatureImageBytes = Uint8Array.from(atob(base64Data), (c) =>
              c.charCodeAt(0)
            );
            const signatureImageEmbed = await pdfDoc.embedPng(
              signatureImageBytes
            );

            // Loop through each page data and apply the signature
            for (const pageData of pagesData) {
              const PageNo = Number(pageData?.PageNo);
              const pageIndex = Math.min(
                Math.max(PageNo - 1, 0),
                totalPages - 1
              );
              const selectedPage = pdfDoc.getPage(pageIndex);
              const PageHeight = Number(pageData?.PageHeight);

              // Loop through each Coord on the page and add the signature
              for (const coord of pageData?.Coords?.Coord || []) {
                const xPosition = Number(coord?.XPosition);
                const yPosition = Number(coord?.YPosition);
                const SignWidth = Number(coord?.Width);
                const SignHeight = Number(coord?.Height);
                const Pyposition = (PageHeight - yPosition) * 3 - 33 + 15;
                const Pxposition = xPosition * 3;

                // Draw the signature for each coordinate
                selectedPage.drawImage(signatureImageEmbed, {
                  x: Pxposition,
                  y: Pyposition,
                  width: SignWidth,
                  height: SignHeight * 3,
                });
              }
            }

            // Save the modified PDF and add it to the ZIP
            const signedPdfBytes = await pdfDoc.save();
            const pdfName = `${ContractNumber}_${ProductCode}_signed.pdf`;
            folder.file(pdfName, signedPdfBytes);
          } else {
            console.warn(
              `PDF URL or signature image not found for Contract: ${ContractNumber}`
            );
          }
        } else {
          console.warn(
            `Failed to fetch PDF for Contract: ${ContractNumber}, ProductCode: ${ProductCode}`
          );
        }
      }

      // Generate the ZIP file
      const zipBlob = await zip.generateAsync({ type: "blob" });
      const url = URL.createObjectURL(zipBlob);

      // Trigger download
      const link = document.createElement("a");
      link.href = url;
      link.download = "contracts.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownloadZip(false);
    } catch (error) {
      console.error("Error downloading ZIP:", error);
      toast.warn("Failed to download ZIP file");
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const InputCheckboxChange = () => {
    setInputChecked((prev) => !prev);
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
    setInputChecked(false);
    setIsSignaturePresent(false);
    setInputChecked(false);
    setSignatureImage(null);
    setUploadedSignatureImage(false);
  };

  const checkSignature = () => {
    // Check if the signature canvas is empty
    setIsSignaturePresent(!sigCanvas.current.isEmpty());
  };

  //  download sign function ====>>>>>>>>>>

  const downloadSignature = () => {
    if (signatureImage) {
      // If the wet signature image is uploaded, download it
      const link = document.createElement("a");
      link.href = signatureImage; // Use the uploaded signature image URL
      link.download = "wet-signature.png";
      link.click();
    } else if (sigCanvas.current && !sigCanvas.current.isEmpty()) {
      // Otherwise, download the e-signature from the canvas
      const dataURL = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      const link = document.createElement("a");
      link.href = dataURL;
      link.download = "signature.png";
      link.click();
    }
  };

  const handleAcceptSign = () => {
    if (sigCanvas.current && !sigCanvas.current.isEmpty()) {
      const dataURL = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      setSignatureImage(dataURL); // Set the signature image in state
    } else {
      console.error("Canvas is either empty or not initialized");
    }
  };

  const handleWetSignClick = () => {
    // Trigger the file input click event
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files?.[0]; // Safely access the file
    if (!file) {
      console.error("No file selected");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result) {
        setSignatureImage(reader.result); // Set the image URL in state
        setIsCanvasDisabled(true);
        sigCanvas.current?.clear();
        setIsSignaturePresent(true);
      } else {
        console.error("FileReader failed to read the file");
      }
    };
    reader.readAsDataURL(file); // Read the file as a data URL
  };

  const handleSaveMenu = () => {
    // alert("save");
    if (onSaveMenu) {
      // alert("save");
      onSaveMenu();
    }
  };
  const content =
    ProductShowAll === 1 ? (
      <div>
        <p>
          Your vehicle is partially Protected. Make it fully secured by
          completing your coverage.
        </p>
      </div>
    ) : ProductShowAll === 2 ? (
      <div>
        <p>
          Secure your peace of mind on the road. Upgrade to below warranties.
        </p>
      </div>
    ) : (
      <div>
        <p>Congratulations! </p>
        <p>Your vehicle is fully Protected.</p>
      </div>
    );

  const ViewSignImg = () => {
    setUploadedSignatureImage((prev) => !prev);
  };

  const handleshareImage = () => {
    setUploadedSignatureImage(false);
    clearSignature();
  };

  // Validate Email field
  const validateEmail = () => {
    const emailRules = validationRules.email;
    const emailError = {};

    // Validate email based on required and pattern rules
    if (emailRules.required) {
      emailError.email = emailRules.messages.required;
    } else if (
      emailRules.pattern &&
      !new RegExp(emailRules.pattern).test(email)
    ) {
      emailError.email = emailRules.messages.pattern;
    }

    // Set the error state for display
    setErrors(emailError);

    // Return whether email is valid
    return Object.keys(emailError).length === 0;
  };

  // Handle changes in the email input
  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);

    console.log("emailValue", emailValue);

    const emailRules = validationRules.email;
    const emailError = {};

    // Validate dynamically when typing
    if (emailRules.required && !emailValue) {
      emailError.email = emailRules.messages.required;
    } else if (
      emailRules.pattern &&
      !new RegExp(emailRules.pattern).test(emailValue)
    ) {
      emailError.email = emailRules.messages.pattern;
    }

    // Update error state dynamically
    if (Object.keys(emailError).length === 0) {
      setErrors({}); // Clear errors
      setSharePolicieDisable(false); // Enable button
    } else {
      setSharePolicieDisable(true); // Disable button
    }
  };

  const fetchPdfData = async (contractNumber, productCode) => {
    try {
      const response = await fetch(`/api/pdf/${contractNumber}/${productCode}`);
      if (!response.ok) {
        throw new Error("Failed to fetch PDF data");
      }
      return await response.blob(); // Return the binary data of the PDF
    } catch (error) {
      console.error("Error fetching PDF data:", error);
      return null;
    }
  };

  // Handle "Share Now" button click
  const handleSharePolicy = async (e) => {
    const emailValue = e.target.value;

    const emailRules = validationRules.email;
    const emailError = {};
    console.log("emailRules", emailRules);
    console.log("emailValue11", emailValue);

    // Validate dynamically when typing
    if (emailRules.required && !emailValue) {
      emailError.email = emailRules.messages.required;
    } else if (
      emailRules.pattern &&
      !new RegExp(emailRules.pattern).test(emailValue)
    ) {
      emailError.email = emailRules.messages.pattern;
    }

    if (!validateEmail()) {
      setSharePolicieDisable(true);
      setErrors(emailError); // Set errors
      // Disable button if email is invalid
    } else {
      setSharePolicieDisable(false); // Enable button if validation passes
    }

    try {
      const attachments = await Promise.all(
        contractPdfs.map(async (pdf) => {
          const pdfData = await fetchPdfData(
            pdf.ContractNumber,
            pdf.Product.ProductCode
          );
          if (pdfData) {
            return {
              filename: `${pdf.Product.ProductName}-${pdf.ContractNumber}.pdf`,
              content: pdfData, // content should be file data here
            };
          }
          return null;
        })
      );

      // Filter out null attachments
      const validAttachments = attachments.filter(
        (attachment) => attachment !== null
      );

      if (validAttachments.length > 0) {
        await sendEmail(validAttachments, emailValue); // Pass email and attachments to the sendEmail function
      } else {
        console.error("No valid PDFs found to send");
      }
    } catch (error) {
      console.error("Error preparing email attachments:", error);
    }
  };

  // Function to send email using SMTP

  const sendEmail = async (attachments, email) => {
    const formData = new FormData();
    // Append text fields: email details (to, subject, html)
    formData.append("to", email); // recipient email
    formData.append("subject", "Policy Sharing"); // email subject
    formData.append("html", "<p>Your policy has been shared successfully.</p>"); // email body

    // Append files to the formData
    attachments.forEach((attachment) => {
      formData.append(
        "files",
        new Blob([attachment.content], { type: "application/pdf" }),
        attachment.filename
      );
    });

    // Send the request to the server
    const response = await fetch("https://api.menuverse.ai/email/send-email", {
      method: "POST",
      headers: {
        authorization: "Bearer " + usertoken,
        "Content-Disposition": "attachment; filename=yourfile.pdf",
      },
      body: formData, // Send form data with files
    });

    if (!response.ok) {
      throw new Error("Failed to send email");
    }

    return response.json();
  };

  // Fetch recommended_service names from API
  useEffect(() => {
    const fetchRecommendedServices = async () => {
      try {
        const response = await axios.get(
          `https://api.menuverse.ai/dealWarranties/show/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + usertoken,
            },
          }
        );

        const recommendedServicesArray = response.data.data.map(
          (item) => item.recommended_service
        );
        setRecommendedServices(recommendedServicesArray);
      } catch (error) {
        console.error("Error fetching recommended services:", error);
      }
    };

    fetchRecommendedServices();
  }, []);

  // Filter out empty strings and create a Set to remove duplicates
  const uniqueServices = [
    ...new Set(recommendedServices.filter((service) => service.trim() !== "")),
  ];
  const isProductRecommended = (title) => uniqueServices.includes(title);

  // useEffect(() => {
  //   const sellPrices = {};
  //   const uniqueProducts = {};

  //   if (productSalePrice !== undefined) {
  //     productSalePrice.forEach((category) => {
  //       Object.entries(category).forEach(([categoryType, products]) => {
  //         products.forEach((product) => {
  //           // Only add the first unique product per category
  //           if (!uniqueProducts[`${categoryType}_${product.id}`]) {
  //             uniqueProducts[`${categoryType}_${product.id}`] = true;
  //             sellPrices[categoryType] = product.sell_price;
  //             console.log(" sell price:", Object.values(sellPrices));
  //             const totalSellprice = Object.values(sellPrices).reduce((index, value) => {
  //               return index + parseFloat(value);
  //             }, 0);
  //             setProductsTotalSellPrice(totalSellprice);
  //           }
  //         });
  //       });
  //     });
  //   }
  //   console.log(" sell price total:", ProductsTotalSellPrice);
  // }, [ProductsTotalSellPrice]);

  return (
    <>
      <div
        className={`col-lg-3 Dashboard-Left-Sidebar ${
          ProductShowAll === 1
            ? "Bg-Warning"
            : ProductShowAll === 2
            ? "Bg-Danger"
            : ""
        }`}
      >
        <div className="head">
          {ProductShowAll === 1 ? (
            <img
              src="/assets/Images/DashboardImages/triangle-warning.svg"
              alt=""
            />
          ) : ProductShowAll >= 2 ? (
            <img
              src="/assets/Images/DashboardImages/triangle-danger.svg"
              alt=""
            />
          ) : ProductShowAll === 0 ? (
            <img src="/assets/Images/DashboardImages/checkVector.svg" alt="" />
          ) : (
            <img src="/assets/Images/DashboardImages/checkVector.svg" alt="" />
          )}

          <div>{content}</div>
        </div>
        {/*========== here is start green sidebar ========== */}

        <div className="Product-Options">
          <div>
            <div className="Recommend_Select_Options">
              {!isChecked && (
                <p>
                  Lock in Peace of Mind: Explore <br /> Our Vehicle Product
                  Options
                </p>
              )}
              {isChecked && (
                <p>
                  <span>VERSA</span> recommendations based on your custom
                  ownership journey
                </p>
              )}
              <div className="Versa_Recommends">
                <div>
                  <span>VERSA</span>
                  <p>Recommends</p>
                </div>
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    onClick={playClickSound}
                  />
                  <span className="custom-checkmark"></span>
                </label>
              </div>
            </div>
            <div className="Product-Options-Items row row-cols-2  gy-3">
              {menuData?.VSC && (
                <ProductItems
                  ProductItemsTitle="VSC"
                  ProductItemsPrice={menuData?.VSC?.price ?? "47.00"}
                  ProuctImage="tools.svg"
                  productDetail={menuData?.VSC}
                  interestData={interestData}
                  termData={termData}
                  second_termData={second_termData}
                  onSaveMenu={handleSaveMenu}
                  Option_Logo={isProductRecommended("VSC") ? isChecked : false}
                  AcDeactive={
                    !isProductRecommended("VSC")
                      ? isChecked
                        ? "Deactive"
                        : ""
                      : ""
                  }
                />
              )}

              {menuData?.GAP && (
                <ProductItems
                  ProductItemsTitle="GAP"
                  ProductItemsPrice={menuData?.GAP?.price ?? "26.54"}
                  ProuctImage="car-crash.svg"
                  productDetail={menuData?.GAP}
                  interestData={interestData}
                  termData={termData}
                  second_termData={second_termData}
                  onSaveMenu={handleSaveMenu}
                  Option_Logo={isProductRecommended("GAP") ? isChecked : false}
                  AcDeactive={
                    !isProductRecommended("GAP")
                      ? isChecked
                        ? "Deactive"
                        : ""
                      : ""
                  }
                />
              )}

              {menuData?.PPM && (
                <ProductItems
                  ProductItemsTitle="PPM"
                  ProductItemsPrice={menuData?.PPM?.price ?? "11.00"}
                  ProuctImage="under-maintenance.svg"
                  productDetail={menuData?.PPM}
                  interestData={interestData}
                  termData={termData}
                  second_termData={second_termData}
                  onSaveMenu={handleSaveMenu}
                  Option_Logo={isProductRecommended("PPM") ? isChecked : false}
                  AcDeactive={
                    !isProductRecommended("PPM")
                      ? isChecked
                        ? "Deactive"
                        : ""
                      : ""
                  }
                />
              )}

              {menuData?.anti_theft && (
                <ProductItems
                  ProductItemsTitle="Anti-theft"
                  ProductItemsPrice={menuData?.anti_theft?.price ?? "0.00"}
                  ProuctImage="anti-theft-system.svg"
                  productDetail={menuData?.anti_theft}
                  interestData={interestData}
                  termData={termData}
                  second_termData={second_termData}
                  onSaveMenu={handleSaveMenu}
                  Option_Logo={
                    isProductRecommended("Anti-theft") ? isChecked : false
                  }
                  AcDeactive={
                    !isProductRecommended("Anti-theft")
                      ? isChecked
                        ? "Deactive"
                        : ""
                      : ""
                  }
                />
              )}

              {menuData?.PDR && (
                <ProductItems
                  ProuctImage="car-painting.svg"
                  ProductItemsTitle="PDR"
                  ProductItemsPrice={menuData?.PDR?.price ?? "14.00"}
                  productDetail={menuData?.PDR}
                  interestData={interestData}
                  termData={termData}
                  second_termData={second_termData}
                  onSaveMenu={handleSaveMenu}
                  Option_Logo={isProductRecommended("PDR") ? isChecked : false}
                  AcDeactive={
                    !isProductRecommended("PDR")
                      ? isChecked
                        ? "Deactive"
                        : ""
                      : ""
                  }
                />
              )}

              {menuData?.windshield_protection && (
                <ProductItems
                  ProuctImage="windshield.svg"
                  ProductItemsTitle="Windshield Protection"
                  ProductItemsPrice={
                    menuData?.windshield_protection?.price ?? "8.99"
                  }
                  productDetail={menuData?.windshield_protection}
                  interestData={interestData}
                  termData={termData}
                  second_termData={second_termData}
                  onSaveMenu={handleSaveMenu}
                  Option_Logo={
                    isProductRecommended("Windshield Protection")
                      ? isChecked
                      : false
                  }
                  AcDeactive={
                    !isProductRecommended("Windshield Protection")
                      ? isChecked
                        ? "Deactive"
                        : ""
                      : ""
                  }
                />
              )}

              {menuData?.TB && (
                <ProductItems
                  ProuctImage="layers.svg"
                  ProductItemsTitle="TotalCase Bundle"
                  ProductItemsPrice={menuData?.TB?.price ?? "55.00"}
                  productDetail={menuData?.TB}
                  interestData={interestData}
                  termData={termData}
                  second_termData={second_termData}
                  onSaveMenu={handleSaveMenu}
                  Option_Logo={
                    isProductRecommended("TotalCase Bundle") ? isChecked : false
                  }
                  AcDeactive={
                    !isProductRecommended("TotalCase Bundle")
                      ? isChecked
                        ? "Deactive"
                        : ""
                      : ""
                  }
                />
              )}

              {menuData?.TWL && (
                <ProductItems
                  ProuctImage="tierVector.svg"
                  ProductItemsTitle="TWL"
                  ProductItemsPrice={menuData?.TWL?.price ?? "12.00"}
                  productDetail={menuData?.TWL}
                  interestData={interestData}
                  termData={termData}
                  second_termData={second_termData}
                  onSaveMenu={handleSaveMenu}
                  Option_Logo={
                    isProductRecommended("TIRE AND WHEEL PROTECTION")
                      ? isChecked
                      : false
                  }
                  AcDeactive={
                    !isProductRecommended("TIRE AND WHEEL PROTECTION")
                      ? isChecked
                        ? "Deactive"
                        : ""
                      : ""
                  }
                />
              )}

              {menuData?.key_replacement && (
                <ProductItems
                  ProuctImage="tools.svg"
                  ProductItemsTitle="Key-Replacement"
                  ProductItemsPrice={menuData?.key_replacement?.price ?? "6.00"}
                  productDetail={menuData?.key_replacement}
                  interestData={interestData}
                  termData={termData}
                  second_termData={second_termData}
                  onSaveMenu={handleSaveMenu}
                  Option_Logo={
                    isProductRecommended("Key-Replacement") ? isChecked : false
                  }
                  AcDeactive={
                    !isProductRecommended("Key-Replacement")
                      ? isChecked
                        ? "Deactive"
                        : ""
                      : ""
                  }
                />
              )}

              {menuData?.LWT && (
                <ProductItems
                  ProuctImage="layers.svg"
                  ProductItemsTitle="LWT"
                  ProductItemsPrice={menuData?.LWT?.price ?? "0.00"}
                  productDetail={menuData?.LWT}
                  interestData={interestData}
                  termData={termData}
                  second_termData={second_termData}
                  onSaveMenu={handleSaveMenu}
                  Option_Logo={!isProductRecommended("LWT") ? isChecked : false}
                  AcDeactive={
                    !isProductRecommended("LWT") ? (isChecked ? "" : "") : ""
                  }
                />
              )}
            </div>
          </div>
          <div className="Left_Sidebar_Footer">
            <div className="Term_Amount_Calulation">
              {" "}
              <TermAmountCalulation
                emi={emiData ?? "620.80"}
                interest={interestData ?? "8"}
                term={termData ?? "72"}
                down={downData ?? "0.00"}
              />
              <TermAmountCalulation
                emi={second_emiData ?? "780.90"}
                interest={interestData ?? "60"}
                term={second_termData ?? "8"}
                down={downData ?? "0.00"}
              />
            </div>
            <button
              className="Sign_And_Verify"
              data-bs-toggle="modal"
              data-bs-target="#ESignature"
              onClick={playClickSound}
            >
              Sign & Verify
            </button>
          </div>
        </div>
      </div>

      {/*========== here is start modal ESignature ========== */}

      <div
        className="modal"
        id="ESignature"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ESignatureMoadal">
          <div className="modal-content border-0">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  playClickSound();
                  clearSignature();
                }}
              ></button>

              <img
                src="/assets/Images/DashboardImages/E-signatureimg.svg"
                alt=""
              />
              <h4>E-signature</h4>
            </div>
            <div className="modal-body text-center p-0">
              <div className="ESignatureTextarea">
                <SignatureCanvas
                  ref={sigCanvas}
                  penColor="black"
                  canvasProps={{
                    width: 500,
                    height: 200,
                    className: "sigCanvas",
                  }}
                  onEnd={checkSignature}
                  disabled={isCanvasDisabled}
                />
                <a onClick={clearSignature}>Clear</a>
                {signatureImage && (
                  <div className="signature-preview">
                    <img
                      src={signatureImage}
                      alt="Wet Signature"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </div>
                )}
              </div>

              <div className="Checkbox_input">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={InputChecked}
                  onChange={InputCheckboxChange}
                  onClick={playClickSound}
                />
                <span>I agree with all my purchases.</span>
              </div>
            </div>
            <div className="modal-footer  justify-content-center">
              <button
                className="Cancel_Btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  playClickSound();
                  clearSignature();
                }}
              >
                Cancel
              </button>
              <button className="WetSign_Btn" onClick={handleWetSignClick}>
                Wet Sign
              </button>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }} // Hide the file input
                onChange={handleFileChange}
              />
              <MyButton
                className=" MyBtn"
                title="Accept E-Sign"
                data-bs-toggle="modal"
                data-bs-target="#GREATJOB"
                disabled={!InputChecked}
                onClick={() => {
                  handleAcceptSign();
                  handleContractSubmit();
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/*========== here is start modal ServicePDFs ========== */}

      <div
        className="modal"
        id="ServicePDFs"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ServicePDFs">
          <div className="modal-content border-0">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  playClickSound();
                  clearSignature();
                }}
              ></button>
              <div className="d-flex align-items-center gap-2">
                <BsFileEarmarkPdfFill style={{ fontSize: "30px" }} />
                <h1 className="modal-title">Contract PDFs</h1>
              </div>
            </div>
            <div className="modal-body text-center">
              {Pdfloading ? (
                <BookLoader />
              ) : (
                <ul
                  style={{
                    listStyle: "auto",
                    textAlign: "left",
                    lineHeight: 2,
                    fontSize: "15px",
                  }}
                >
                  {contractPdfs.map((pdf, index) => (
                    <li style={{ marginBottom: "15px" }}>
                      <span style={{ display: "block", width: "75%" }}>
                        {pdf.Product.ProductName} - {pdf.ContractNumber}
                      </span>

                      <button
                        className="btn btn-sm btn-warning"
                        style={{
                          position: "absolute",
                          right: "12px",
                          marginTop: "-30px",
                        }}
                        onClick={() => {
                          handlePdfDownload(
                            pdf.ContractNumber,
                            pdf.Product.ProductCode,
                            index
                          );
                        }}
                      >
                        {downloadingIndex === index ? "Downloading..." : "View"}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="modal-footer  justify-content-center">
              <MyButton
                title="Share"
                data-bs-toggle="modal"
                data-bs-target="#Share_Policies"
              ></MyButton>

              <button
                className="MyBtn"
                onClick={() => {
                  handleDownloadZip();
                }}
              >
                {DownloadZip ? "Downloading..." : "Download Zip File"}
              </button>
              <MyButton
                className="MyBtn"
                title="Done"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  clearSignature();
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/*========== here is start modal GREATJOB ========== */}

      <div
        className="modal"
        id="GREATJOB"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog DashboardModals modal-fade-transform">
          <div className="modal-content border-0 ">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                playClickSound();
                clearSignature();
              }}
            ></button>
            <div className="modal-body p-0 text-center">
              {!ContractLoading ? (
                !uploadedSignatureImage ? (
                  <div>
                    <img
                      className=""
                      src="/assets/Images/Otherimages/dislike_image.svg"
                      alt="GREATJOB"
                    />
                    <h4>Great Job!</h4>
                    <p>
                      We encourage you to share the final copy to your email
                      rather than printing it to save trees.
                    </p>
                  </div>
                ) : (
                  <div className="User-SignImage">
                    <img src={signatureImage} alt="" />
                  </div>
                )
              ) : (
                <BookLoader />
              )}
            </div>
            <div className="modal-footer border-0 justify-content-center p-0">
              {!ContractLoading && (
                <>
                  {/* <MyButton className="Empty_Btn border-0" title="Print" data-bs-dismiss="modal" aria-label="Close"></MyButton> */}
                  <MyButton
                    className="bgColor_Btn"
                    title="View"
                    data-bs-toggle="modal"
                    data-bs-target="#ServicePDFs"
                    disabled={!InputChecked}
                  ></MyButton>
                </>
              )}

              {/* <button className="Empty_Btn border-0" onClick={downloadSignature}>
                Print
              </button>
              <button className="bgColor_Btn" onClick={ViewSignImg}>
                {!uploadedSignatureImage ? "View" : "Hide"}
              </button>
              <button
                onClick={handleshareImage}
                className="MyBtn"
                data-bs-toggle="modal"
                data-bs-target="#Share_Policies"
              >
                Share
              </button> */}
            </div>
          </div>
          {!ContractLoading && (
            <div className="GREATJOB_Message">
              <p>
                Save 2 pounds of wood by choosing “SHARE” option. This will
                eventually help to reduce carbon footprints and save our mother
                earth.
              </p>
            </div>
          )}
        </div>
      </div>

      {/*========== here is start modal Share Policies ========== */}

      <div
        className="modal"
        id="Share_Policies"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog DashboardModals p-4">
          <div className="modal-content p-0 border-0 Edit_Product">
            <div className="modal-header justify-content-start gap-2 p-4">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={playClickSound}
              ></button>
              <FaShareAlt style={{ fontSize: "32px" }} />
              <h4>Share Policies</h4>
            </div>
            <div className="modal-body p-4">
              <div className="gap-2 d-flex flex-column">
                <InputFiled
                  onChange={handleEmailChange}
                  value={email}
                  lableValue="Email address"
                  InputType="emial"
                  Inputplaceholder="cynthiaj@gmail.com"
                />
                {errors.email && (
                  <span className="text-danger">{errors.email}</span>
                )}
              </div>
            </div>
            <div className="modal-footer justify-content-center p-4">
              <button
                className="w-100 MyBtn"
                onClick={handleSharePolicy}
                value={email}
                data-bs-toggle={!SharePolicieDisable ? "modal" : undefined}
                data-bs-target={
                  !SharePolicieDisable ? "#Policy_Shared" : undefined
                }
              >
                Share Now
              </button>
            </div>
          </div>
          <div className="GREATJOB_Message">
            <p>
              You’ve unlocked 300 reward points that you can utilize when you
              come again for your vehicle service. Don’t forget to remind us.
            </p>
          </div>
        </div>
      </div>

      {/*========== here is start modal Policy Shared ========== */}

      <div
        className="modal"
        id="Policy_Shared"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog DashboardModals modal-fade-transform">
          <div className="modal-content border-0 ">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                playClickSound();
                clearSignature();
              }}
            ></button>
            <div className="modal-body p-0 text-center ">
              <img
                className=""
                src="/assets/Images/Otherimages/dislike_image.svg"
                alt="GREATJOB"
              />
              <h4>Policy Shared</h4>
              <p>
                A link has been shared on your email and phone number. You can
                download the policy by clicking on that link
              </p>
            </div>
            <div className="modal-footer border-0 justify-content-center p-0">
              <button
                className="MyBtn"
                data-bs-toggle="modal"
                data-bs-target="#Reviewsmodal"
                onClick={() => {
                  playClickSound();
                  clearSignature();
                }}
              >
                Share Review
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardLeftSidebar;
