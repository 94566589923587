import React, { useRef, useState } from "react";
import { useAudio } from "../../../AudioContext";
import { FaShareAlt } from "react-icons/fa";
import InputFiled from "../FormStructure/InputField";
import { toast } from "react-toastify";

const TableHeader = ({
  title,
  showFilters,
  showSareLink,
  Monthlyfinance,
  onSearch,
  onFilterChange,
}) => {
  const searchInputRef = useRef(null);
  const [selectedDealType, setSelectedDealType] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [email, setEmail] = useState("");
  const { playClickSound } = useAudio();
  const usertoken = localStorage.getItem("token");

  let half = "col-md-12 mb-3";
  const handleSearchChange = () => {
    if (onSearch && searchInputRef.current) {
      onSearch(searchInputRef.current.value);
    }
  };

  // const handleDealTypeChange = (e) => {
  //   const value = e.target.value || "AllDeal";
  //   setSelectedDealType(value);
  // };

  // const handleClassChange = (e) => {
  //   const value = e.target.value || "AllClass";
  //   setSelectedClass(value);
  // };

  const handleDealTypeChange = (e) => {
    const value = e.target.value;
    setSelectedDealType((prev) => (prev === value ? "" : value));
  };

  const handleClassChange = (e) => {
    const value = e.target.value;
    setSelectedClass((prev) => (prev === value ? "" : value));
  };

  const SearchData = () => {
    playClickSound();

    const dealType = selectedDealType || "AllDeal";
    const classType = selectedClass || "AllClass";

    if (onFilterChange) {
      onFilterChange({ dealType, classType });
    }
  };

  const closemodal = () => {
    const modalCloseButton = document.querySelector("#mailmodal");
    if (modalCloseButton) {
      modalCloseButton.click();
    }
  };

  const sendEmail = async (e) => {
    const isShared = "true";
    const userId = localStorage.getItem("userId");
    const userName = localStorage.getItem("userName");
    const siteLink = `https://54.183.100.74:8001/AddDeal/${usertoken}?=${userId}?=${userName}?=${isShared}`;

    e.preventDefault();
    const payload = {
      to: email, // Target email address
      subject: "Shared Link", // Email subject
      html: `<p>Hi ${localStorage.getItem("firstName")},</p>
             <p>You can access the shared site link here: <a href="${siteLink}">Click to open link</a></p>
             <p>Best regards,</p>`, // HTML body
    };

    try {
      const response = await fetch(
        "https://api.menuverse.ai/email/send-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${usertoken}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Email sent successfully!", result);
        toast.dismiss();
        toast.success("Link shared Successfully!");
        setEmail("");
        closemodal();
      } else {
        console.error(
          "Failed to send email.",
          response.status,
          response.statusText
        );
        toast.dismiss();
        toast.warn("Failed to share the link. Please try again.");
      }
    } catch (err) {
      console.error("An error occurred while sending email.", err);
      toast.dismiss();
      toast.warn("Failed to share the link. Please try again.");
    }
  };

  return (
    <>
      <div className="table_header">
        <h3>{title}</h3>
        {showFilters && (
          <div className="Table_filters">
            <div className="dropdown d-grid">
              <a
                href="/"
                className="Filter"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                onClick={playClickSound}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M11.6667 20C11.4864 20 11.311 19.9415 11.1667 19.8333L7.83341 17.3333C7.72991 17.2557 7.64591 17.1551 7.58805 17.0393C7.53019 16.9236 7.50007 16.796 7.50007 16.6667V11.9833L1.6534 5.40583C1.23817 4.9374 0.967048 4.35901 0.872632 3.7402C0.778216 3.12138 0.864519 2.48846 1.12117 1.91751C1.37782 1.34656 1.79389 0.861882 2.31938 0.521721C2.84488 0.181561 3.45742 0.000395349 4.0834 0L15.9167 0C16.5427 0.000734209 17.1551 0.182199 17.6803 0.522589C18.2056 0.862978 18.6214 1.34781 18.8778 1.91882C19.1342 2.48983 19.2202 3.12273 19.1256 3.74146C19.0309 4.36018 18.7596 4.93841 18.3442 5.40667L12.5001 11.9833V19.1667C12.5001 19.3877 12.4123 19.5996 12.256 19.7559C12.0997 19.9122 11.8878 20 11.6667 20Z"
                    fill="white"
                  />
                </svg>
                <span>Filter</span>
                <svg
                  className="Rotate-Filter"
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="7"
                  viewBox="0 0 14 7"
                  fill="none"
                >
                  <path
                    d="M12.8719 0.758824C12.7906 0.676811 12.6938 0.611716 12.5872 0.567293C12.4806 0.522871 12.3662 0.5 12.2507 0.5C12.1352 0.5 12.0208 0.522871 11.9142 0.567293C11.8076 0.611716 11.7108 0.676811 11.6294 0.758824L7.62194 4.76632C7.5406 4.84834 7.44382 4.91343 7.3372 4.95785C7.23057 5.00228 7.1162 5.02515 7.00069 5.02515C6.88518 5.02515 6.77082 5.00228 6.66419 4.95785C6.55756 4.91343 6.46079 4.84834 6.37944 4.76632L2.37194 0.758824C2.2906 0.676811 2.19382 0.611716 2.0872 0.567293C1.98057 0.522871 1.8662 0.5 1.75069 0.5C1.63518 0.5 1.52082 0.522871 1.41419 0.567293C1.30756 0.611716 1.21079 0.676811 1.12944 0.758824C0.966474 0.922766 0.875 1.14454 0.875 1.3757C0.875 1.60686 0.966474 1.82863 1.12944 1.99257L5.14569 6.00882C5.63788 6.5004 6.30507 6.77651 7.00069 6.77651C7.69632 6.77651 8.3635 6.5004 8.85569 6.00882L12.8719 1.99257C13.0349 1.82863 13.1264 1.60686 13.1264 1.3757C13.1264 1.14454 13.0349 0.922766 12.8719 0.758824Z"
                    fill="white"
                  />
                </svg>
              </a>

              <div className="dropdown-menu">
                <div className="border-bottom p-3">
                  <h5>Deal Type</h5>
                  <div className="d-flex align-items-center gap-2">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="AllDeal"
                        name="DealType"
                        onClick={playClickSound}
                        checked={selectedDealType === "AllDeal"}
                        onChange={handleDealTypeChange}
                      />
                      <label className="form-check-label">All</label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Cash"
                        name="DealType"
                        onClick={playClickSound}
                        checked={selectedDealType === "Cash"}
                        onChange={handleDealTypeChange}
                      />
                      <label className="form-check-label">Cash</label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Finance"
                        name="DealType"
                        onClick={playClickSound}
                        checked={selectedDealType === "Finance"}
                        onChange={handleDealTypeChange}
                      />
                      <label className="form-check-label">Finance</label>
                    </div>
                  </div>
                </div>

                <div className="border-bottom p-3 mb-3">
                  <h5>Class</h5>
                  <div className="d-flex align-items-center gap-2 flex-wrap">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="AllClass"
                        name="ClassType"
                        onClick={playClickSound}
                        checked={selectedClass === "AllClass"}
                        onChange={handleClassChange}
                      />
                      <label className="form-check-label">All</label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="New"
                        name="ClassType"
                        onClick={playClickSound}
                        checked={selectedClass === "New"}
                        onChange={handleClassChange}
                      />
                      <label className="form-check-label">New</label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Used"
                        name="ClassType"
                        onClick={playClickSound}
                        checked={selectedClass === "Used"}
                        onChange={handleClassChange}
                      />
                      <label className="form-check-label">Used</label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Certified Pre-Owned"
                        name="ClassType"
                        onClick={playClickSound}
                        checked={selectedClass === "Certified Pre-Owned"}
                        onChange={handleClassChange}
                      />
                      <label className="form-check-label">
                        Certified Pre-Owned
                      </label>
                    </div>
                  </div>
                </div>
                <div className="filter_earch_btn mb-2">
                  <button type="button" className="" onClick={SearchData}>
                    SEARCH
                  </button>
                </div>
              </div>
            </div>

            <div className="Search">
              <input
                type="text"
                id="search"
                placeholder="Search Deal Here.."
                ref={searchInputRef}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        )}
        {showSareLink && (
          <div className="ShareLink">
            <a
              href="/"
              data-bs-toggle="modal"
              data-bs-target="#shareAddDealLink"
              onClick={playClickSound}
            >
              <FaShareAlt className="HiShareInHeader" />
              <span>Share a Link</span>
            </a>
          </div>
        )}
        {Monthlyfinance && (
          <div className="Monthly_Finance_text">
            <p className="m-0">Monthly finance payment estimate</p>
          </div>
        )}
      </div>

      <div
        className="modal"
        id="shareAddDealLink"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog Waiting_pop">
          <div className="modal-content border-0">
            <div className="modal-header p-4">
              <div className="d-flex align-items-center gap-2">
                <FaShareAlt className="HiShare FaShareAlt" />

                <h1 className="modal-title" id="exampleModalLabel">
                  Share Your Email to Get the Link
                </h1>
              </div>
              <button
                type="button"
                id="mailmodal"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={playClickSound}
              ></button>
            </div>
            <div className="modal-body px-4">
              <InputFiled
                InputBoxClassname={half}
                InputType="email"
                InputName="EmailAddress"
                lableValue="Email Address"
                Inputplaceholder="name@example.com"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
            <div className="modal-footer border-0 justify-content-center mt-2">
              <button onClick={sendEmail} className="MyBtn">
                Share Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableHeader;
