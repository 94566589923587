import React, { forwardRef, useState } from "react";
import "../../Dashboard.css";
import { NavLink } from "react-router-dom";
import { HiPlus } from "react-icons/hi2";
import { useAudio } from "../../../../AudioContext";

const Seviceoptions = forwardRef(
  (
    {
      ProductId,
      WarrantyImages,
      ServicesTitle,
      OptionMSG,
      onServiceClick,
      ondltproductClick,
      onRemoveProduct,
      HandletriviaTextVideo,
      menuData,
      onClickProduct,
    },
    ref
  ) => {
    const productNames = {
      GAP: "GAP",
      "Windshield Protection": "windshield_protection",
      "Key/Remote Replacement": "key_replacement",
      "Vehicle Service Contract": "VSC",
      "Paintless Dent Repair": "PDR",
      "Pre-paid Maintenance": "PPM",
      "Anti-theft": "anti_theft",
      "Paint Protection": "paint_protection",
      "Tire & Wheel Protection": "TWL",
      "TotalCare Shield": "TB",
    };
    const { playClickSound } = useAudio();

    const handleClick = () => {
      playClickSound();
      const productId = menuData[productNames[ServicesTitle]]?.menu_id;
      // console.log("ServicesTitle", ServicesTitle);
      // console.log("menuData[productNames?.ServicesTitle]?.id", menuData);
      if (productId) {
        console.log("menuData[productNames?.ServicesTitle]?.id", productId);

        onClickProduct(productId);
      }

      console.log("menuData", menuData);
      onServiceClick(ServicesTitle);
      ondltproductClick(ProductId);
      // setTimeout(() => {
      //   HandletriviaTextVideo(ProductId);
      // }, 4000);
    };

    const AddProduct = (event) => {
      playClickSound();
      const productDbId = menuData[productNames[ServicesTitle]]?.menu_id;

      const productData = {
        productDbId: productDbId,
        ProductId: ProductId,
      };

      event.stopPropagation();
      onRemoveProduct(productData);
    };

    return (
      <>
        <div
          ref={ref}
          className={`option-With-Text ${ProductId}`}
          onClick={handleClick}
        >
          <HiPlus className="Add-Product-Icon" onClick={AddProduct} />
          <NavLink
            data-bs-toggle="modal"
            data-bs-target="#TotalCareShieldAndComman"
          >
            <div className="Option clickTarget">
              <img
                className="clickTarget"
                src={`/assets/Images/DashboardImages/${WarrantyImages}`}
                alt=""
              />
              <span className="clickTarget">{OptionMSG}</span>
            </div>
            <p>{ServicesTitle}</p>
          </NavLink>
        </div>
      </>
    );
  }
);

export default Seviceoptions;
