import React, { useState } from "react";
import InputField from "../../CommonFolder/FormStructure/InputField";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import validationRules from "../../CommonFolder/FormStructure/validationRules.json";

const SendCode = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};

    // Validate the email field only
    if (validationRules.email) {
      const value = email;
      const rules = validationRules.email;

      if (rules.required && !value) {
        newErrors.email = rules.messages.required;
      } else if (rules.pattern && !new RegExp(rules.pattern).test(value)) {
        newErrors.email = rules.messages.pattern;
      }
    }

    setError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    // Validate the form before submitting
    if (!validateForm()) {
      return;
    }

    try {
      const response = await axios.post(
        "https://api.menuverse.ai/users/forgot-password",
        {
          email: email,
        }
      );

      if (response.status === 200) {
        navigate("/VerifyCode");
      }
    } catch (error) {
      // Handle error
      setError({ api: "Failed to send code. Please try again." });
      console.error("API error:", error);
    }
  };

  localStorage.setItem("email", email);

  return (
    <>
      <form className="row g-3 w-100" onSubmit={handleSubmit}>
        <div className=" Sign_In_Box">
          <div className=" position-relative">
            <InputField
              InputBoxClassname="col-md-12 mb-4"
              InputType="email"
              InputName="email"
              Inputplaceholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            {error?.api ? (
              <p className="Login-Error-msg">{error.api}</p>
            ) : error?.email ? (
              <p className="Login-Error-msg">{error.email}</p>
            ) : null}
          </div>

          <button className="w-100 MyBtn" type="submit">
            Send Code
          </button>
        </div>
      </form>
    </>
  );
};

export default SendCode;
