import React, { useState, useEffect, Suspense, useRef } from "react";
import QuestionnaireHeader from "./QuestiononeData/QuestionnaireHeader";
import InputFiled from "../CommonFolder/FormStructure/InputField";
import "./Questionnaire.css";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Loader from "../CommonFolder/Loader";
import { useVideo } from "../../VideoContext";
import axios from "axios";
import { toast } from "react-toastify";
import validationRules from "../CommonFolder/FormStructure/validationRules.json";
const QuestionnaireBodyData = React.lazy(() =>
  import("./QuestiononeData/QuestionnaireBodyData")
);
const VersaAiBot = React.lazy(() => import("./QuestiononeData/VersaAiBot"));
const QuestionnaireFooter = React.lazy(() =>
  import("./QuestiononeData/QuestionnaireFooter")
);

const Questionone = ({ setIsLogin }) => {
  const { playPreloadedQuestionVideos } = useVideo();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showReferAndEarn, setShowReferAndEarn] = useState(false);
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [Gamevideo, setGamevideo] = useState("");
  const [Gamevideocontinue, setGamevideocontinue] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { firstName } = location.state || {};
  const [handleContinueActive, sethandleContinueActive] = useState(false);
  const usertoken = localStorage.getItem("token");
  const childRef = useRef(null);
  const [selectedOptions, setSelectedOptions] = useState();
  const [handleselectedOptions, sethandleselectedOptions] = useState();
  const [offValues, setOffValues] = useState([]);
  const [randomCongratsId, setrandomCongratsId] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [ReferCoupon, setReferCoupon] = useState();
  const [PostNumberAnswer, setPostNumberAnswer] = useState(1);

  const handleGamevideo = (e) => {
    setGamevideocontinue(false);
    if (e === true) {
      setGamevideo("1");
    } else {
      setGamevideo("video url");
    }
    toast.dismiss();
  };

  const CongratsContinueGame = () => {
    console.log("CongratsContinueGame");
    setGamevideocontinue(true);
  };

  // handleContinue Function  *************

  const handleContinue = () => {
    if (
      isLastQuestion &&
      !showReferAndEarn &&
      currentQuestion.questionType != "checkbox"
    ) {
      setShowReferAndEarn(true);
    } else if (
      isLastQuestion &&
      !showReferAndEarn &&
      currentQuestion.questionType == "checkbox"
    ) {
      if (handleContinueActive == true) {
        handlePostAnswer(handleselectedOptions.optionId);
        setShowReferAndEarn(true);
        toast.dismiss();
      } else {
        setCurrentQuestionIndex(currentQuestionIndex);
        toast.dismiss();
        toast.warn("Please select an option.");
      }
    } else if (
      showReferAndEarn &&
      ((!ReferData.firstName?.trim() &&
        !ReferData.lastName?.trim() &&
        !ReferData.email?.trim() &&
        !ReferData.phoneNumber?.trim()) ||
        isFormValid)
    ) {
      toast.dismiss();
      navigate(`/presentation/${id}`);
    } else if (showReferAndEarn && !isFormValid) {
      toast.dismiss();
      console.warn("ReferData", ReferData);
      toast.warn("Oops! It seems like some details are missing or incorrect.");
    } else if (currentQuestion.questionType == "checkbox" && !isLastQuestion) {
      if (handleContinueActive == true) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        toast.dismiss();
        handlePostAnswer(handleselectedOptions.optionId);
        // console.log("selectedOptionId", handleselectedOptions.optionId);
      } else {
        setCurrentQuestionIndex(currentQuestionIndex);
        toast.dismiss();
        toast.warn("Please select an option.");
      }
    } else if (
      currentQuestion.questionType == "number" &&
      currentQuestion.type == "Question"
    ) {
      setPostNumberAnswer(PostNumberAnswer + 1);
      setTimeout(() => {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }, 600);
    } else if (
      currentQuestion.questionType == "checkbox" &&
      currentQuestion.type == "Game"
    ) {
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
    if (childRef.current) {
      childRef.current.childMethod();
    }
  };

  // Function to send data to the API *************

  const sendReferData = async () => {
    toast.dismiss();

    try {
      const response = await axios.post(
        "https://api.menuverse.ai/refer/create",
        {
          first_name: ReferData.firstName,
          last_name: ReferData.lastName,
          email: ReferData.email,
          phone: ReferData.phoneNumber,
          deal_id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${usertoken}`,
          },
        }
      );

      if (response) {
        if (ReferCoupon == null || ReferCoupon == undefined) {
          setReferCoupon(20);
        } else {
          setReferCoupon(response.data.data.couponDetail.off);
        }
      }

      return response.data;
    } catch (error) {
      console.error("Error sending referral data:", error);
      throw error;
    }
  };

  const showPreviousLink = () => {
    if (showReferAndEarn) {
      setShowReferAndEarn(false);
    } else {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const selectedValueForWaiting = localStorage.getItem(
    "selectedValueForWaiting"
  );

  useEffect(() => {
    fetch(
      `https://api.menuverse.ai/questions/show/${id}/${selectedValueForWaiting}`,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          localStorage.clear();
          if (response.status === 401 || response.status === 403) {
            setIsLogin(false);
            navigate("/login");
          }
          throw new Error(`Error: ${response.status}`);
        }
        return response.json();
      })
      .then((responseData) => {
        const coupons = responseData.data[currentQuestionIndex]?.coupons || [];
        const fetchedOffValues = coupons.map((coupon) => coupon);
        setOffValues(fetchedOffValues);
        setData(responseData.data);
        const i = 1;
        for (const questionData of responseData.data) {
          if (i <= 3) {
            playPreloadedQuestionVideos(
              questionData.video,
              "videoQuestion" + questionData.id
            );
          }
          i++;
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [id]);

  const handlePostNumberAnswer = () => {
    setPostNumberAnswer(PostNumberAnswer + 1);
    if (
      currentQuestion.questionType == "checkbox" &&
      currentQuestion.type == "Game"
    ) {
      handlePostAnswer(handleselectedOptions.optionId);
    }
  };

  const handlePostAnswer = async (selectedOptionId) => {
    const Dealid = parseInt(window.location.pathname.split("/")[2], 10);

    try {
      const response = await fetch("https://api.menuverse.ai/answers/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
        body: JSON.stringify({
          question_id: data[currentQuestionIndex].id,
          deal_id: Dealid,
          selected_option_id: parseInt(selectedOptionId),
          answer_value: handleselectedOptions?.index,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to post answer");
      }

      const responseData = await response.json();
    } catch (error) {}
  };

  const currentQuestion = data[currentQuestionIndex];
  const isLastQuestion = currentQuestionIndex === data.length - 1;

  const renderQuestionnaireBody = () => {
    if (!currentQuestion) {
      return null;
    }

    const { questionType, buttonTitles, questionOptions } = currentQuestion;

    switch (questionType) {
      case "slider":
        return (
          <Suspense fallback={<Loader />}>
            <QuestionnaireBodyData
              RangeSet={true}
              DoorDefaultValue={questionOptions.map((option) => option.default)}
              ref={childRef}
              sethandleselectedOptions={sethandleselectedOptions}
              sethandleContinueActive={sethandleContinueActive}
            />
          </Suspense>
        );
      case "number":
        return (
          <Suspense fallback={<Loader />}>
            <QuestionnaireBodyData
              PlusMinus={true}
              PlusMinusTitle={questionOptions}
              PlusMinusDefaultValue={questionOptions.map(
                (option) => option.default_value
              )}
              questionId={currentQuestion.id}
              sethandleselectedOptions={sethandleselectedOptions}
              sethandleContinueActive={sethandleContinueActive}
              PostNumberAnswer={PostNumberAnswer}
            />
          </Suspense>
        );
      case "checkbox":
        return (
          <Suspense fallback={<Loader />}>
            <QuestionnaireBodyData
              Determining={true}
              handlePostAnswer={handlePostAnswer}
              buttonTitles={buttonTitles}
              titleofoptions={questionOptions}
              questionId={currentQuestion.id}
              sethandleContinueActive={sethandleContinueActive}
              selectedOptions={selectedOptions}
              sethandleselectedOptions={sethandleselectedOptions}
              PostNumberAnswer={PostNumberAnswer}
            />
          </Suspense>
        );
      default:
        return null;
    }
  };

  // ReferData state ****************

  const [ReferData, setReferData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Create a full updated object
    const updatedData = { ...ReferData, [name]: value };

    // Validate the form with the fully updated data
    validateForm(updatedData);

    // Update the state
    setReferData(updatedData);
  };

  useEffect(() => {
    console.log("ReferData phone number", ReferData.phoneNumber);
    console.log("ReferData phone number", ReferData.phoneNumber.length);
  }, [ReferData.phoneNumber]);

  const validateForm = (data) => {
    const newErrors = {};

    for (const [field, rules] of Object.entries(validationRules)) {
      const value = data[field];
      if (value !== undefined) {
        if (rules.required && !value) {
          newErrors[field] = rules.messages.required;
        } else if (rules.minLength && value.length <= rules.minLength) {
          newErrors[field] = rules.messages.minLength;
          console.log("one");
        } else if (rules.maxLength && value.length > rules.maxLength) {
          newErrors[field] = rules.messages.maxLength;
          console.log("two");
        } else if (rules.pattern && !new RegExp(rules.pattern).test(value)) {
          newErrors[field] = rules.messages.pattern;
          console.log("three");
        } else if (rules.length && value.length !== rules.length) {
          newErrors[field] = rules.messages.length;
          console.log("four");
        }
      }
    }

    const isValid = Object.keys(newErrors).length === 0;
    console.log("isValid", isValid);
    setIsFormValid(isValid);
    return isValid;
  };

  return (
    <>
      <main className="Questionnaire QuestionnaireOne">
        <div className="container">
          <QuestionnaireHeader questionNumber={currentQuestionIndex + 1} />
          <div className="QuestionnaireBody container">
            <div className="row justify-content-center pe-3">
              {!showReferAndEarn ? (
                <div className="col-lg-9 col-md-12">
                  <div>
                    {data.map((question, index) => (
                      <div key={index}>
                        {index === currentQuestionIndex && (
                          <h4>{question.title}</h4>
                        )}
                      </div>
                    ))}
                  </div>
                  {renderQuestionnaireBody()}
                </div>
              ) : (
                <div className="col-lg-9 col-md-12">
                  <div className="ReferAndEarn">
                    <h4>Refer someone to earn a discount you can use today.</h4>
                    <div className="row row-cols-lg-2 row-cols-md-1">
                      <InputFiled
                        InputBoxClassname="DisabledInputs"
                        InputType="text"
                        InputID="firstName"
                        InputName="firstName"
                        lableValue="First Name"
                        value={ReferData.firstName}
                        onChange={handleChange}
                        Inputplaceholder="Stephen"
                      />

                      <InputFiled
                        InputBoxClassname="DisabledInputs"
                        InputType="text"
                        InputID="lastName"
                        InputName="lastName"
                        lableValue="Last Name"
                        value={ReferData.lastName}
                        onChange={handleChange}
                        Inputplaceholder="Jones"
                      />

                      <InputFiled
                        InputBoxClassname="DisabledInputs"
                        InputType="email"
                        InputID="email"
                        InputName="email"
                        lableValue="Email Address"
                        value={ReferData.email}
                        onChange={handleChange}
                        Inputplaceholder="stephenj@gmail.com"
                      />

                      <InputFiled
                        InputBoxClassname="DisabledInputs"
                        InputType="tel"
                        InputID="phoneNumber"
                        InputName="phoneNumber"
                        lableValue="Phone Number"
                        value={ReferData.phoneNumber}
                        onChange={handleChange}
                        Inputplaceholder="(925) 676-5490"
                        maxlength="10"
                        minlength="9"
                      />
                    </div>
                  </div>
                </div>
              )}
              <Suspense fallback={<Loader />}>
                <VersaAiBot
                  Gamevideo={Gamevideo}
                  currentQuestionIndex={currentQuestionIndex}
                  data={data}
                  isLastQuestion={isLastQuestion}
                  Gamevideocontinue={Gamevideocontinue}
                  setGamevideocontinue={setGamevideocontinue}
                  showReferAndEarn={showReferAndEarn}
                  randomCongratsId={randomCongratsId}
                />
              </Suspense>
            </div>
          </div>
          <Suspense fallback={<Loader />}>
            <QuestionnaireFooter
              ReferCoupon={ReferCoupon}
              previousLink={showPreviousLink}
              onNext={handleContinue}
              onGamePopup={handleGamevideo}
              showPrevious={currentQuestionIndex > 0 || showReferAndEarn}
              currentQuestionIndex={currentQuestionIndex}
              continueModel={`/Questionone/${id}`}
              isLastQuestion={isLastQuestion}
              showReferAndEarn={showReferAndEarn}
              CongratsContinueGame={CongratsContinueGame}
              setGamevideocontinue={setGamevideocontinue}
              offValues={offValues}
              setrandomCongratsId={setrandomCongratsId}
              isFormValid={isFormValid}
              handlePostAnswer={handlePostAnswer}
              sendReferData={sendReferData}
              handleContinueActive={handleContinueActive}
              handlePostNumberAnswer={handlePostNumberAnswer}
            />
          </Suspense>
        </div>
      </main>
    </>
  );
};

export default Questionone;
