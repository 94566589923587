import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
  useMemo,
} from "react";

import "./Navbar.css";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { RxEnterFullScreen, RxExitFullScreen } from "react-icons/rx";
import "bootstrap/dist/css/bootstrap.min.css";
import { Peer } from "peerjs";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import socket from "../../SocketContext";
import { useAudio } from "../../AudioContext";
import { toast } from "react-toastify";
import LockSvg from "../CommonFolder/CommonImages/LockSvg";
import { FiEye, FiEyeOff } from "react-icons/fi";
import LogoutIcon from "./LogoutIcon";

const NavbarModal = React.lazy(() => import("../NavBar/NavbarModal"));

const Navbar = ({
  onLogout,
  handle,
  sharedState,
  IsPinHasInId,
  setIsPinHasInId,
  selectedValueTrue,
  setSelectedValueTrue,
  selectedValueForWaiting,
  setselectedValueForWaiting,
}) => {
  const [elapsedTime, setElapsedTime] = useState(0);
  const [userName, setUserName] = useState("");
  const [fullScreen, setfullScreen] = useState(false);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [peerId, setPeerId] = useState("");
  const [remotePeerId, setRemotePeerId] = useState("");
  const [peer, setPeer] = useState(null);
  const [call, setCall] = useState(null);
  const [stream, setStream] = useState(null);
  const [incomingCall, setIncomingCall] = useState(null);
  const [isCallPending, setIsCallPending] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(false);
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const audioRef = useRef(null);
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]); // State to store OTP digits
  const usertoken = localStorage.getItem("token");
  const [isCreatePinModal, setisCreatePinModal] = useState(false);
  const [isUnlockScreenModal, setisUnlockScreenModal] = useState(false);
  const [isLockScreen, setIsLockScreen] = useState(false);
  const closeButtonRef = useRef(null);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [customerId, setCustomerId] = useState(null);
  const [customerUrls, setCustomerUrls] = useState([]);
  const [singleCustomerUrl, setSingleCustomerUrl] = useState(null);
  const [isEventEmitted, setIsEventEmitted] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [token, setToken] = useState("");

  //////////////////////////////////////////////////////////  Get Dealer Name

  const [dealerName, setDealerName] = useState("");

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem("token"); // Get token from local storage

        const response = await axios.get(
          "https://api.menuverse.ai/users/getuserDetail",
          { headers: { Authorization: `Bearer ${token}` } } // Pass token in headers
        );

        const dealer = response.data.data.dealer;
        setDealerName(dealer ? dealer.firm_name : "Dealer not found");
      } catch (err) {
        console.log("Error fetching user details:", err);
        setDealerName("Error fetching dealer name");
      } finally {
        setLoading(false); // Update loading state regardless of success or error
      }
    };

    fetchUserDetails();
  }, []);

  useEffect(() => {
    const queryString = window.location.search; // Get the query string
    const params = new URLSearchParams(queryString); // Create URLSearchParams object

    const id = params.get("userId"); // Get userId
    const pass = params.get("pass"); // Get pass

    if (id) {
      setCustomerId(id); // Set customerId state
      localStorage.setItem("userId", id); // Store userId in localStorage
    }

    if (pass) {
      setToken(pass); // Set token state
      localStorage.setItem("token", pass); // Store token in localStorage
    }
    if (id) {
      localStorage.setItem("controller", "admin");
    }
  }, []);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const isAdmin = window.localStorage.getItem("controller");
    const customer_Id = localStorage.getItem("userId");
    if (isAdmin !== "admin") {
      storeCustomerUrl(peerId);
    } else if (isAdmin == "admin") {
    } // Call your function whenever the location changes
  }, [location, peerId]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///// get admin peerId   ////

  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchCustomers = async () => {
    try {
      // const token = localStorage.getItem("token");
      // // Assuming the token is stored in cookies
      // const response = await axios.get("https://api.menuverse.ai/users/show", {
      //   headers: {
      //     Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
      //   },
      //   params: {
      //     role: 1,
      //   },
      // });

      // setCustomers(response.data.data);
      // console.log("response.data.data", response.data.data); // Update with response data
      const token = localStorage.getItem("token");
      const response = await axios.get(
        "https://api.menuverse.ai/users/getadminpeer",
        {
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
          },
        }
      );

      // Wrap the single customer data in an array to allow mapping
      setCustomers([response.data.data]);
      console.log("getadminpeerId", response.data.data); // Check the data
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  ///////////////////////// redirection control /////////////////////

  useEffect(() => {
    // console.log("useEffect triggered");

    if (!socket) {
      console.error("Socket is not initialized.");
      return;
    }
    const isAdmin = localStorage.getItem("controller");
    console.log("isAdmin", isAdmin);
    const customer_Id = localStorage.getItem("userId");

    // Listen for the singleCustomerUrl event
    const handleSingleCustomerUrl = (url) => {
      setSingleCustomerUrl(url); // Store the URL in state
      if (isAdmin === "admin" && url.url) {
        console.log("Received:", url.url);
        const newUrl = url.url.split("http://localhost:3001")[1]; // Extract path after localhost:3000
        console.log("Navigating to new URL:", newUrl);
        navigate(newUrl); // Navigate to the new URL
      }
    };

    // Listen for socket event
    socket.on("singleCustomerUrl", handleSingleCustomerUrl);
    // Poll for customer URL every second
    // const intervalId = setInterval(() => {
    if (isAdmin === "admin") {
      console.log(
        "Emitting getSingleCustomerUrl event for customer_Id:",
        customer_Id
      );
      socket.emit("getSingleCustomerUrl", customer_Id); // Emit event to request customer URL
    }
    // }, 1000); // Emit every 1000 ms (1 second)
    console.log("Location changes");
    // Cleanup function to avoid memory leaks
    return () => {
      //   console.log("Cleaning up listeners and interval");
      socket.off("singleCustomerUrl", handleSingleCustomerUrl); // Cleanup listener
      // clearInterval(intervalId); // Clear interval
    };
  }, [location.pathname]);

  ////////////////////////////////////// video call /////////////////////////////////

  useEffect(() => {
    const newPeer = new Peer();

    newPeer.on("open", (id) => {
      setPeerId(id);
    });

    newPeer.on("call", (incoming) => {
      setIncomingCall(incoming);
      setIsCallPending(true);
      setShowModal(true);
      if (audioRef.current) {
        audioRef.current.play();
      }

      incoming.on("stream", (remoteStream) => {
        remoteVideoRef.current.srcObject = remoteStream;
      });
    });

    setPeer(newPeer);

    return () => {
      newPeer.destroy();
    };
  }, []);

  // console.log("peerIdddd",peerId);

  const storeCustomerUrl = async (peerId) => {
    const customerId = window.localStorage.getItem("userId");
    const isAdmin = window.localStorage.getItem("controller");
    const url = window.location.href.split("?")[0];
    const callId = peerId;
    const cookieValue = Cookies.get("loginData");

    if (isAdmin !== "admin" && customerId) {
      try {
        const ctoken = window.localStorage.getItem("token");
        // console.log("ctoken", ctoken);
        const customerId = window.localStorage.getItem("userId");

        const response = await axios.post(
          `https://api.menuverse.ai/users/update/${customerId}`,
          { url: url, peerId: callId, token: ctoken }, // Sending URL and Peer ID
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${ctoken}`, // Pass the token here
            },
          }
        );
        console.log("Response:-", response.data);
        socket.emit("getSingleCustomerUrl", customerId);
      } catch (error) {
        console.error("Error storing customer URL:", error);
      }
    } else {
      console.log(
        "API call skipped. User is admin, customer ID not found, or token is missing."
      );
    }
  };

  // console.log("peerid",peerId);
  useEffect(() => {
    if (peer) {
      peer.on("data", (data) => {
        const event = JSON.parse(data);

        if (event.type === "mousemove") {
          const mouseMoveEvent = new MouseEvent("mousemove", {
            clientX: event.x,
            clientY: event.y,
          });
          document
            .elementFromPoint(event.x, event.y)
            .dispatchEvent(mouseMoveEvent);
        } else if (event.type === "click") {
          const clickEvent = new MouseEvent("click", {
            clientX: event.x,
            clientY: event.y,
          });
          document.elementFromPoint(event.x, event.y).dispatchEvent(clickEvent);
        } else if (event.type === "keydown") {
          const keyDownEvent = new KeyboardEvent("keydown", {
            key: event.key,
          });
          document.dispatchEvent(keyDownEvent);
        }
      });
    }
  }, [peer]);

  const shouldHideSpan =
    location.pathname === "/AddDeal" || location.pathname === "/";
  const { playClickSound } = useAudio();

  // here is start navbar timer code ****

  const [countdown, setCountdown] = useState(0);
  const [countdownActive, setCountdownActive] = useState(false);
  const [IsShared, setIsShared] = useState(false);

  useEffect(() => {
    const TimeReset = location.pathname === "/";
    if (TimeReset) {
      setselectedValueForWaiting(0);
    }
  }, [location.pathname]);

  useEffect(() => {
    const initializeCountdown = () => {
      const countdownEndTime = localStorage.getItem("countdownEndTime"); // Retrieve stored end time
      const savedElapsedTime = localStorage.getItem("elapsedTime");

      if (countdownEndTime) {
        const timeRemaining = Math.max(
          parseInt(countdownEndTime, 10) - Date.now(),
          0
        ); // Calculate time left

        if (timeRemaining > 0) {
          setCountdown(Math.floor(timeRemaining / 1000)); // Set remaining countdown in seconds
          setCountdownActive(true); // Start the countdown
        } else {
          // Countdown is over, switch to elapsed time mode
          setCountdown(0);
          setElapsedTime(parseInt(savedElapsedTime || "0", 10));
        }
      }
      if (selectedValueForWaiting) {
        // Initialize new countdown if no stored end time exists
        const newCountdown = selectedValueForWaiting * 60; // Convert sharedState to seconds
        setCountdown(newCountdown);
        localStorage.setItem(
          "countdownEndTime",
          Date.now() + newCountdown * 1000
        ); // Save end time
        setCountdownActive(true);
      }
    };

    initializeCountdown(); // Initialize on component mount
  }, [selectedValueForWaiting]); // Only run once on mount

  // Timer logic for countdown and elapsed time
  useEffect(() => {
    let countdownInterval;
    let elapsedInterval;

    if (countdownActive && countdown > 0) {
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          const newCountdown = prevCountdown - 1;
          localStorage.setItem("countdown", newCountdown);

          if (newCountdown <= 0) {
            clearInterval(countdownInterval);
            setCountdownActive(false);
            setElapsedTime(0);
            localStorage.removeItem("countdownEndTime");
            return 0;
          }
          return newCountdown;
        });
      }, 1000);
    } else if (!countdownActive && countdown === 0) {
      // Start elapsed time tracking
      elapsedInterval = setInterval(() => {
        setElapsedTime((prevTime) => {
          const newElapsedTime = prevTime + 1;
          localStorage.setItem("elapsedTime", newElapsedTime);
          return newElapsedTime;
        });
      }, 1000);
    }

    return () => {
      clearInterval(countdownInterval);
      clearInterval(elapsedInterval);
    };
  }, [countdownActive, countdown]);

  // Handle sharedState changes
  useEffect(() => {
    if (
      selectedValueForWaiting &&
      selectedValueForWaiting !==
        localStorage.getItem("selectedValueForWaiting")
    ) {
      const newCountdownValue = selectedValueForWaiting * 60; // Convert to seconds
      setCountdown(newCountdownValue);
      localStorage.setItem(
        "countdownEndTime",
        Date.now() + newCountdownValue * 1000
      ); // Save new countdown end time to local storage
      setCountdownActive(true); // Start countdown
    }
  }, [selectedValueForWaiting]);

  // Format time function
  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const displayTime = countdownActive ? countdown : elapsedTime;

  // here is finish my timer code *******

  useEffect(() => {
    const storedUserName = localStorage.getItem("userName");
    if (storedUserName || userName) {
      const decodedUserName = decodeURIComponent(storedUserName);
      setUserName(decodedUserName);
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    onLogout();
    document.cookie =
      "IsPinHasInId=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
  };

  const isAddDealPageArr = ["/AddDeal", "/DetailVerification"];
  const isAddDealPage = isAddDealPageArr.includes(window.location.pathname);

  const toggleFullScreen = () => {
    if (!fullScreen) {
      handle.enter();
    } else {
      handle.exit();
    }
    setfullScreen(!fullScreen);
  };

  const startCall = (peerid) => {
    if (!peerid || !peer) return;

    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((mediaStream) => {
        setStream(mediaStream);
        localVideoRef.current.srcObject = mediaStream;

        const outgoingCall = peer.call(peerid, mediaStream);

        outgoingCall.on("stream", (remoteStream) => {
          remoteVideoRef.current.srcObject = remoteStream;
        });

        outgoingCall.on("close", () => {
          endCall();
        });

        setCall(outgoingCall);
      });
  };

  const acceptCall = () => {
    if (!incomingCall || !peer) return;

    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((mediaStream) => {
        setStream(mediaStream);
        localVideoRef.current.srcObject = mediaStream;
        incomingCall.answer(mediaStream);

        incomingCall.on("stream", (remoteStream) => {
          remoteVideoRef.current.srcObject = remoteStream;
        });

        incomingCall.on("close", () => {
          endCall();
        });

        setCall(incomingCall);
        setIncomingCall(null);
        setIsCallPending(false);

        // Stop the incoming call sound
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
        }
      });
  };

  const declineCall = () => {
    if (incomingCall) {
      incomingCall.close();
      setIncomingCall(null);
      setIsCallPending(false);
      // setIsCallPending(true);
      setShowModal(false);

      // Stop the incoming call sound
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0; // Reset the playback time
      }
    }
  };

  const endCall = () => {
    if (call) {
      call.close();
    }
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
    setCall(null);
    setStream(null);
    localVideoRef.current.srcObject = null;
    remoteVideoRef.current.srcObject = null;
    setShowModal(false); // Close the modal when the call ends
  };

  const toggleVideo = () => {
    if (stream) {
      const videoTrack = stream.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.enabled = !videoTrack.enabled;
        setIsVideoMuted(!videoTrack.enabled);
      }
    }
  };

  const toggleAudio = () => {
    if (stream) {
      const audioTrack = stream.getAudioTracks()[0];
      if (audioTrack) {
        audioTrack.enabled = !audioTrack.enabled;
        setIsAudioMuted(!audioTrack.enabled);
      }
    }
  };

  const startScreenShare = async () => {
    if (!peer || !call) return;

    try {
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });

      const videoTrack = screenStream.getVideoTracks()[0];
      const sender = call.peerConnection
        .getSenders()
        .find((s) => s.track.kind === "video");

      if (sender) {
        sender.replaceTrack(videoTrack);
      }

      localVideoRef.current.srcObject = screenStream;
      setIsScreenSharing(true);

      videoTrack.onended = () => {
        stopScreenShare();
      };
    } catch (error) {
      console.error("Error during screen sharing:", error);
    }
  };

  const stopScreenShare = async () => {
    if (!peer || !call || !isScreenSharing) return;

    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });

      const videoTrack = mediaStream.getVideoTracks()[0];
      const sender = call.peerConnection
        .getSenders()
        .find((s) => s.track.kind === "video");

      if (sender) {
        sender.replaceTrack(videoTrack);
      }

      localVideoRef.current.srcObject = mediaStream;
      setIsScreenSharing(false);
    } catch (error) {
      console.error("Error while stopping screen sharing:", error);
    }
  };

  // const [customerUrls, setCustomerUrls] = useState([]);
  // const [singleCustomerUrl, setSingleCustomerUrl] = useState(null); // New state for single customer URL

  // useEffect(() => {
  //   const socket = io('http://localhost:3000'); // Replace with your server URL

  //   // Emit event to request customer URLs
  //   socket.emit('getCustomerUrls');

  //   // Listen for customer URLs response
  //   socket.on('customerUrls', (urls) => {
  //     setCustomerUrls(urls);
  //     console.log("Received customer URLs:", urls);
  //   });

  //   // Listen for error messages
  //   socket.on('error', (message) => {
  //     console.error(message);
  //   });

  //   // Retrieve customerId from local storage
  //   const customerId = localStorage.getItem('userId'); // Adjust the key as necessary
  //   const isAdmin = localStorage.getItem('controller');
  //   // Emit event to get the single customer URL
  //   if (customerId) {
  //     socket.emit('getSingleCustomerUrl', customerId);
  //   }

  //   // Listen for single customer URL response
  //   socket.on('singleCustomerUrl', (url) => {
  //     console.log("Received single customer URL:", url);
  //     setSingleCustomerUrl(url);

  //     if (isAdmin === 'admin') {
  //       // Navigate to the URL (removing the 'https://api.menuverse.ai/' part)
  //       const newUrl = url.url.split('https://api.menuverse.ai')[1]; // Get the path after localhost:3002
  //       navigate(newUrl); // Use the navigate function to go to the new URL
  //     }
  //   });

  //   // Clean up on component unmount
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, [navigate]);

  useEffect(() => {
    const shared = localStorage.getItem("isShared") === "true";
    setIsShared(shared);
  }, []);
  const DealId = localStorage.getItem("DealId");
  const destinationUrl = !IsShared ? "/" : `/DetailVerification/${DealId}`;

  // CREATE PIN FUNCTIONS

  const handleChange = (index, e) => {
    const value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numeric values
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").replace(/[^0-9]/g, ""); // Allow only numeric values
    const newOtp = [...otp];
    const length = Math.min(pastedData.length, inputRefs.length);

    for (let i = 0; i < length; i++) {
      newOtp[i] = pastedData[i];
      if (inputRefs[i]) {
        inputRefs[i].current.value = pastedData[i];
      }
    }
    setOtp(newOtp);

    if (length === inputRefs.length) {
      inputRefs[length - 1].current.focus();
    } else if (length > 0) {
      inputRefs[length].current.focus();
    }
  };

  // const handleKeyPress = (e) => {
  //   const charCode = e.which ? e.which : e.keyCode;
  //   if (charCode < 48 || charCode > 57) {
  //     e.preventDefault();
  //   }
  // };

  const handleKeyDown = async (index, e) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      if (otp[index] !== "") {
        newOtp[index] = "";
        setOtp(newOtp);
      } else if (index > 0) {
        inputRefs[index - 1].current.focus();
        newOtp[index - 1] = "";
        setOtp(newOtp);
      }
    } else if (e.key === "Enter") {
      // Check if all OTP inputs are filled
      if (otp.join("").length === inputRefs.length) {
        if (isUnlockScreenModal || !isCreatePinModal) {
          await handleUnlockScreen();
        } else {
          await handleCreatePin();
        }
      } else {
        toast.warn("Please complete the OTP.");
      }
    }
  };

  // handlechnage password API call

  const handleChangePin = async () => {
    const userId = Number(localStorage.getItem("userId"));
    const pin = Number(otp.join(""));

    if (pin.toString().length !== 4) {
      toast.dismiss();
      toast.warn("Please enter a valid 4-digit PIN.");
      return;
    }

    // Validate Password
    if (!password) {
      toast.dismiss();
      toast.warn("Password is required.");
      return;
    }

    try {
      const response = await axios.post(
        "https://api.menuverse.ai/users/create-pin",
        {
          user_id: userId,
          pin: pin,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${usertoken}`,
          },
        }
      );

      toast.dismiss();
      toast.success("PIN change successfully!");
      setPassword("");
      setShowPassword(false);
      if (closeButtonRef.current) {
        closeButtonRef.current.click();
      }
      document.cookie = "IsPinHasInId=true; path=/; secure; samesite=strict";
      setIsPinHasInId(true);
      console.log("Create Pin Response data:", response.data);
    } catch (error) {
      toast.dismiss();

      if (error.response) {
        toast.error("Failed to create PIN.");
      } else if (error.request) {
        toast.error("No response from the server. Please try again later.");
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };

  // handleCreatePin  API call

  const handleCreatePin = async () => {
    const userId = Number(localStorage.getItem("userId"));
    const pin = Number(otp.join(""));

    if (pin.toString().length !== 4) {
      toast.dismiss();
      toast.warn("Please enter a valid 4-digit PIN.");
      return;
    }

    try {
      const response = await axios.post(
        "https://api.menuverse.ai/users/create-pin",
        {
          user_id: userId,
          pin: pin,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${usertoken}`,
          },
        }
      );

      toast.dismiss();
      toast.success("PIN created successfully!");
      if (closeButtonRef.current) {
        closeButtonRef.current.click();
      }
      document.cookie = "IsPinHasInId=true; path=/; secure; samesite=strict";
      setIsPinHasInId(true);
      console.log("Create Pin Response data:", response.data);
    } catch (error) {
      toast.dismiss();

      if (error.response) {
        toast.error("Failed to create PIN.");
      } else if (error.request) {
        toast.error("No response from the server. Please try again later.");
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };

  const CreatePin = () => {
    setisCreatePinModal(true);
    setisUnlockScreenModal(false);
    setOtp(["", "", "", ""]);
  };
  const UnlockScreen = () => {
    setisCreatePinModal(false);
    setisUnlockScreenModal(true);
    setOtp(["", "", "", ""]);
  };

  // handleUnlockScreen API call

  const handleUnlockScreen = async () => {
    const userId = Number(localStorage.getItem("userId"));
    const pin = Number(otp.join(""));

    if (pin.toString().length !== 4) {
      toast.dismiss();
      toast.warn("Please enter a valid 4-digit PIN.");
      return;
    }

    try {
      const response = await axios.post(
        "https://api.menuverse.ai/users/unlock",
        {
          user_id: userId,
          pin: pin,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${usertoken}`,
          },
        }
      );

      toast.dismiss();
      setIsLockScreen(false);
      if (closeButtonRef.current) {
        closeButtonRef.current.click();
      }
      document.cookie =
        "setIsLockScreen=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
      // Cookies.set("setIsLockScreen", true, { expires: 30 });

      console.log("Unlock Response data:", response.data);
    } catch (error) {
      toast.dismiss();
      if (error.response) {
        toast.warn("Failed to unlock screen.");
      } else if (error.request) {
        toast.error("No response from the server. Please try again later.");
      } else {
        toast.error(
          "An error occurred while unlocking the screen. Please try again later."
        );
      }
    }
  };

  const lockScreen = () => {
    setIsLockScreen(true);
    Cookies.set("IsPinHasInId", true, { expires: 30 });
    Cookies.set("setIsLockScreen", true, { expires: 30 });
  };

  useEffect(() => {
    const lockScreenCookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("setIsLockScreen="));

    if (lockScreenCookie) {
      setIsLockScreen(true);
    } else {
      setIsLockScreen(false);
    }
  }, []);

  useEffect(() => {
    if (IsPinHasInId === true) {
      Cookies.set("IsPinHasInId", true, { expires: 30 });
    }
  }, [IsPinHasInId]);

  // Function to check cookie if a IsPinHasInId cookie exists
  useEffect(() => {
    const checkCookie = () => {
      const cookies = document.cookie
        .split(";")
        .map((cookie) => cookie.trim())
        .find((cookie) => cookie.startsWith("IsPinHasInId="));

      if (cookies && cookies.split("=")[1] === "true") {
        setIsPinHasInId(true);
      } else {
        setIsPinHasInId(false);
      }
    };

    checkCookie();
  }, []);
  // Function to check cookie if a setIsLockScreen cookie exists
  useEffect(() => {
    const checkCookie = () => {
      const cookies = document.cookie
        .split(";")
        .map((cookie) => cookie.trim())
        .find((cookie) => cookie.startsWith("setIsLockScreen="));

      if (cookies && cookies.split("=")[1] === "true") {
        setIsLockScreen(true);
      } else {
        setIsLockScreen(false);
      }
    };

    checkCookie();
  }, []);

  return (
    <>
      <header>
        <nav className="navbar navbar-expand-md py-0 w-100">
          <div className="container-fluid px-lg-20">
            <div className="left_column">
              <NavLink to={destinationUrl} aria-label="Read">
                {dealerName ? (
                  <>
                    <span className="image-class" onClick={playClickSound}>
                      {dealerName}
                    </span>
                  </>
                ) : (
                  <img
                    src="/Images/Group34011.png"
                    className="Black-Menu"
                    alt=""
                    onClick={playClickSound}
                  />
                )}
                <img
                  src="/Images/Menu-logo-white.png"
                  className="White-Menu"
                  alt=""
                  onClick={playClickSound}
                />
              </NavLink>

              <img src="/Images/Frame12.png" alt="" />
            </div>
            <div className="text-end">
              <button
                className="d-md-none border-0 px-2 py-1 RxHamburgerMenu"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasResponsive"
                aria-controls="offcanvasResponsive"
              >
                <RxHamburgerMenu />
              </button>
            </div>

            <audio ref={audioRef} className="d-none">
              <source src="/Images/skype-incoming.mp3" type="audio/mpeg" />
            </audio>

            <div
              className="offcanvas-md offcanvas-end"
              id="offcanvasResponsive"
              aria-labelledby="offcanvasResponsiveLabel"
            >
              <div className="offcanvas-header justify-content-end ">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  data-bs-target="#offcanvasResponsive"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <div className="Right_column Mobile-Sidebar-ele">
                  <div className="Mobile-profile">
                    {/* dynamic user profile images here */}
                    <img
                      src="/Images/Group18.png"
                      alt=""
                      className="Profile_img"
                    />
                    <span>{userName}</span>
                  </div>
                  {IsPinHasInId && (
                    <button
                      className="lock-screen-button"
                      onClick={() => lockScreen()}
                    >
                      <LockSvg />
                    </button>
                  )}

                  <button
                    className="FullScreen_Button"
                    onClick={toggleFullScreen}
                    aria-label="Fullscreen"
                  >
                    {fullScreen ? (
                      <RxExitFullScreen onClick={playClickSound} />
                    ) : (
                      <RxEnterFullScreen onClick={playClickSound} />
                    )}
                    <span className="Mobile-sidebar-option">Fullscreen</span>
                  </button>

                  {!shouldHideSpan && (
                    <>
                      <img
                        src="/Images/phone-call.png"
                        style={{ width: "24px" }}
                        onClick={() => setShowModal(true)}
                        alt=""
                      />
                      <div
                        className="Clock_box"
                        style={{ display: isAddDealPage ? "none" : "block" }}
                      >
                        <div className="d-flex align-items-center CLock-inner">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                          >
                            <g clipPath="url(#clip0_2394_613)">
                              <path
                                d="M9 0.5C4.03725 0.5 0 4.53725 0 9.5C0 14.4628 4.03725 18.5 9 18.5C13.9628 18.5 18 14.4628 18 9.5C18 4.53725 13.9628 0.5 9 0.5ZM12 10.25H9C8.586 10.25 8.25 9.91475 8.25 9.5V5C8.25 4.58525 8.586 4.25 9 4.25C9.414 4.25 9.75 4.58525 9.75 5V8.75H12C12.4148 8.75 12.75 9.08525 12.75 9.5C12.75 9.91475 12.4148 10.25 12 10.25Z"
                                fill="#2E302E"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_2394_613">
                                <rect
                                  width="18"
                                  height="18"
                                  fill="white"
                                  transform="translate(0 0.5)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <span>{formatTime(displayTime)}</span>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="d-flex align-items-center Lanuage-Inner">
                    <label className="form-check-label">ENG</label>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        onClick={playClickSound}
                        aria-label="Search"
                      />
                    </div>
                    <label className="form-check-label">ESP</label>
                  </div>
                  <div className="Line">|</div>

                  <a
                    href="/"
                    className="Setting_Link"
                    data-bs-toggle="modal"
                    data-bs-target="#SettingsModal"
                    aria-label="Read"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      onClick={playClickSound}
                    >
                      <g clipPath="url(#clip0_2394_624)">
                        <path
                          d="M21.0004 12C21.0002 11.4483 20.95 10.8977 20.8504 10.355L23.8934 8.6L20.8934 3.4L17.8494 5.159C17.009 4.43993 16.0432 3.88194 15.0004 3.513V0H9.00042V3.513C7.95768 3.88194 6.99187 4.43993 6.15142 5.159L3.10742 3.4L0.107422 8.6L3.15042 10.355C2.95053 11.4426 2.95053 12.5574 3.15042 13.645L0.107422 15.4L3.10742 20.6L6.15142 18.842C6.99195 19.5607 7.95775 20.1184 9.00042 20.487V24H15.0004V20.487C16.0432 20.1181 17.009 19.5601 17.8494 18.841L20.8934 20.6L23.8934 15.4L20.8504 13.645C20.95 13.1023 21.0002 12.5517 21.0004 12ZM15.0004 12C15.0004 12.5933 14.8245 13.1734 14.4948 13.6667C14.1652 14.1601 13.6967 14.5446 13.1485 14.7716C12.6003 14.9987 11.9971 15.0581 11.4152 14.9424C10.8332 14.8266 10.2987 14.5409 9.8791 14.1213C9.45954 13.7018 9.17382 13.1672 9.05807 12.5853C8.94231 12.0033 9.00172 11.4001 9.22878 10.8519C9.45585 10.3038 9.84036 9.83524 10.3337 9.50559C10.8271 9.17595 11.4071 9 12.0004 9C12.7961 9 13.5591 9.31607 14.1217 9.87868C14.6844 10.4413 15.0004 11.2044 15.0004 12Z"
                          fill="#2E302E"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2394_624">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span className="Mobile-sidebar-option">Settings</span>
                  </a>
                  <div className="Mobile-logout">
                    <NavLink
                      className="dropdown-item logout"
                      onClick={handleLogout}
                    >
                      <LogoutIcon />
                      <span>Logout</span>
                    </NavLink>
                  </div>

                  <div
                    className="dropdown Logout_dropdown"
                    onClick={playClickSound}
                  >
                    {!IsShared ? (
                      <a
                        herf="#"
                        className="Profile_Link dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src="/Images/Group18.png"
                          alt=""
                          className="Profile_img"
                        />
                        <div>
                          <p>Your F&I </p>
                          <span>{userName}</span>
                        </div>
                      </a>
                    ) : (
                      <div className="Profile_Link-IsShared">
                        <img
                          src="/Images/Group18.png"
                          alt=""
                          className="Profile_img"
                        />
                        <div className="Profile_Link-data">
                          <p>Your F&I </p>
                          <span>{userName}</span>
                        </div>
                      </div>
                    )}

                    <ul className="dropdown-menu">
                      <li className="Create-PIN-item">
                        <NavLink
                          className="dropdown-item"
                          data-bs-toggle="modal"
                          data-bs-target="#PassCodeModal"
                          onClick={() => CreatePin()}
                        >
                          <LockSvg />
                          <span>
                            {!IsPinHasInId ? "Create PIN" : "Change PIN"}
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="dropdown-item logout"
                          onClick={handleLogout}
                        >
                          <LogoutIcon />
                          <span>Logout</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>

      {isLockScreen && (
        <section id="LockScreen">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 "></div>
              <div className="col-lg-5 ">
                <button
                  className="proceedToUnlocked"
                  data-bs-toggle="modal"
                  data-bs-target="#PassCodeModal"
                  onClick={() => UnlockScreen()}
                >
                  Unlock
                </button>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* <Modal 
      show={showModal} onHide={() => setShowModal(false)} size="xl" centered> */}
      <Modal
        show={showModal}
        onHide={null}
        size="xl"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Video Call</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="video-call-container">
            <div className="video-wrapper">
              <video
                ref={localVideoRef}
                autoPlay
                playsInline
                muted
                className="local-video"
              />
              <video
                ref={remoteVideoRef}
                autoPlay
                playsInline
                className="remote-video"
              />
            </div>

            {isCallPending && incomingCall && (
              <div className="call-prompt">
                <h3>Incoming Call from {incomingCall.peer}</h3>
                <Button
                  onClick={acceptCall}
                  className="accept-button"
                  variant="success"
                >
                  Accept
                </Button>
                <Button
                  onClick={declineCall}
                  className="decline-button"
                  variant="danger"
                >
                  Decline
                </Button>
              </div>
            )}

            <div className="controls">
              {!call && (
                <>
                  {/* <input
                    type="text"
                    value={remotePeerId}
                    onChange={(e) => setRemotePeerId(e.target.value)}
                    placeholder="Enter Remote Peer ID"
                    className="peer-id-input"
                  /> */}
                  {customers.map((customer) => (
                    <a key={customer.id}>
                      <Button
                        onClick={() => startCall(customer.peerId)}
                        className="control-button"
                        variant="primary"
                      >
                        Start Call
                      </Button>
                    </a>
                  ))}
                </>
              )}

              {call && (
                <>
                  <div className="d-flex abmu">
                    <img
                      className="endcall"
                      onClick={endCall}
                      src="/Images/endcall.png"
                      alt=""
                    />
                    <Button
                      onClick={toggleVideo}
                      className="control-button but-vi"
                      variant="secondar"
                    >
                      {isVideoMuted ? (
                        <img
                          className="fontic"
                          src="/Images/video-slash.png"
                          alt=""
                        />
                      ) : (
                        <img
                          className="fontic"
                          src="/Images/video.png"
                          alt=""
                        />
                      )}
                    </Button>
                    <Button
                      onClick={toggleAudio}
                      className="control-button but-vi"
                      variant="secondar"
                    >
                      {isAudioMuted ? (
                        <img className="fontic" src="/Images/mute.png" alt="" />
                      ) : (
                        <img
                          className="fontic"
                          src="/Images/voice.png"
                          alt=""
                        />
                      )}
                    </Button>
                    <Button
                      variant="warning"
                      onClick={
                        isScreenSharing ? stopScreenShare : startScreenShare
                      }
                    >
                      {isScreenSharing ? "Stop Shareing" : "Screen Shareing"}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ===== here is start create pin ======= */}

      <div
        className="modal"
        id="PassCodeModal"
        aria-labelledby="exampleModalLabel"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div className="modal-dialog Waiting_pop rounded-3 p-0">
          <div className="modal-content border-0">
            <div className="modal-header p-4">
              <div className="d-flex align-items-center gap-2">
                <LockSvg />
                <h1 className="modal-title" id="exampleModalLabel">
                  {!isUnlockScreenModal && isCreatePinModal && !IsPinHasInId
                    ? "Create Passcode"
                    : setIsPinHasInId && !isUnlockScreenModal
                    ? "Change Pin"
                    : "Enter Passcode"}
                </h1>
              </div>
              <button
                type="button"
                ref={closeButtonRef}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  playClickSound();
                  setOtp(["", "", "", ""]);
                }}
              ></button>
            </div>
            <div className="modal-body px-4">
              <div className="Create-Pin my-4">
                {inputRefs.map((ref, index) => (
                  <input
                    key={index}
                    type="text"
                    className="form-control"
                    maxLength={1}
                    ref={ref}
                    onChange={(e) => handleChange(index, e)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    onPaste={handlePaste}
                    value={otp[index]}
                    inputMode="numeric"
                  />
                ))}
              </div>
              {!isUnlockScreenModal && IsPinHasInId ? (
                <div className="input-group-append d-grid">
                  <label className="form-label">Password</label>
                  <div className="Change-Pin-Password ">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className=" form-control"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                    <button
                      className="btn Eye_Button"
                      type="button"
                      onClick={() => {
                        playClickSound();
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? (
                        <FiEyeOff className="clickTarget" />
                      ) : (
                        <FiEye
                          className="clickTarget"
                          onClick={() => {
                            playClickSound();
                          }}
                        />
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="modal-footer justify-content-center my-3">
              <button
                className="MyBtn w-100 mx-4"
                onClick={
                  isUnlockScreenModal || !isCreatePinModal
                    ? handleUnlockScreen
                    : !isUnlockScreenModal && IsPinHasInId
                    ? handleChangePin
                    : handleCreatePin
                }
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      <NavbarModal />
    </>
  );
};

export default Navbar;
