import React from "react";

const LogoutIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 32 32" fill="none">
      <g clipPath="url(#clip0_771_3280)">
        <path
          d="M15.476 19C15.2108 19 14.9564 19.1054 14.7689 19.2929C14.5814 19.4804 14.476 19.7348 14.476 20V23C14.476 23.7956 14.1599 24.5587 13.5973 25.1213C13.0347 25.6839 12.2717 26 11.476 26H9C8.20435 26 7.44129 25.6839 6.87868 25.1213C6.31607 24.5587 6 23.7956 6 23V9C6 8.20435 6.31607 7.44129 6.87868 6.87868C7.44129 6.31607 8.20435 6 9 6H11.476C12.2717 6 13.0347 6.31607 13.5973 6.87868C14.1599 7.44129 14.476 8.20435 14.476 9V12C14.476 12.2652 14.5814 12.5196 14.7689 12.7071C14.9564 12.8946 15.2108 13 15.476 13C15.7412 13 15.9956 12.8946 16.1831 12.7071C16.3706 12.5196 16.476 12.2652 16.476 12V9C16.4744 7.67441 15.9471 6.40356 15.0098 5.46622C14.0724 4.52888 12.8016 4.00159 11.476 4H9C7.67441 4.00159 6.40356 4.52888 5.46622 5.46622C4.52888 6.40356 4.00159 7.67441 4 9L4 23C4.00159 24.3256 4.52888 25.5964 5.46622 26.5338C6.40356 27.4711 7.67441 27.9984 9 28H11.476C12.8016 27.9984 14.0724 27.4711 15.0098 26.5338C15.9471 25.5964 16.4744 24.3256 16.476 23V20C16.476 19.7348 16.3706 19.4804 16.1831 19.2929C15.9956 19.1054 15.7412 19 15.476 19Z"
          fill="#E15454"
        />
        <path
          d="M26.867 13.8786L22.281 9.29259C22.1888 9.19708 22.0784 9.1209 21.9564 9.06849C21.8344 9.01608 21.7032 8.9885 21.5704 8.98734C21.4376 8.98619 21.3059 9.01149 21.183 9.06177C21.0602 9.11205 20.9485 9.18631 20.8546 9.2802C20.7607 9.37409 20.6865 9.48574 20.6362 9.60864C20.5859 9.73154 20.5606 9.86321 20.5618 9.99599C20.5629 10.1288 20.5905 10.26 20.6429 10.382C20.6953 10.504 20.7715 10.6143 20.867 10.7066L25.129 14.9696L10 14.9996C9.73478 14.9996 9.48043 15.1049 9.29289 15.2925C9.10536 15.48 9 15.7344 9 15.9996C9 16.2648 9.10536 16.5192 9.29289 16.7067C9.48043 16.8942 9.73478 16.9996 10 16.9996L25.188 16.9686L20.865 21.2926C20.7695 21.3848 20.6933 21.4952 20.6409 21.6172C20.5885 21.7392 20.5609 21.8704 20.5598 22.0032C20.5586 22.136 20.5839 22.2676 20.6342 22.3905C20.6845 22.5134 20.7587 22.6251 20.8526 22.719C20.9465 22.8129 21.0582 22.8871 21.181 22.9374C21.3039 22.9877 21.4356 23.013 21.5684 23.0118C21.7012 23.0107 21.8324 22.9831 21.9544 22.9307C22.0764 22.8783 22.1868 22.8021 22.279 22.7066L26.865 18.1206C27.4277 17.5583 27.744 16.7955 27.7444 16C27.7447 15.2045 27.4292 14.4414 26.867 13.8786Z"
          fill="#E15454"
        />
      </g>
      <defs>
        <clipPath id="clip0_771_3280">
          <rect width="24" height="24" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoutIcon;
